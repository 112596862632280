import React from 'react';
import './ModalCloseWindowWarning.scss';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface PropsType {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function ModalCloseWindowWarning({ open, onCancel, onConfirm }: PropsType) {
  const { t } = useTranslation();
  return (
    <Modal
      title={false}
      closable={false}
      visible={open}
      width={415}
      footer={[
        <Button id="ModalCloseWindowWarningCloseButton" key="close" className="route-content_loyalty-close-button" onClick={onConfirm}>
          {t('modules.modal_close_window_warning_user.0')}
        </Button>,
        <Button
          id="ModalCloseWindowWarningCancelButton"
          key="cancel"
          className="route-content_loyalty-cancel-button"
          type="primary"
          onClick={onCancel}
          autoFocus={true}
        >
          {t('modules.modal_close_window_warning_user.1')}
        </Button>,
      ]}
    >
      <div className="route-content_loyalty-modal-title-block">
        <CloseCircleOutlined className="route-content_loyalty-close-circle" />
        <h3 className="route-content_loyalty-modal-title">{t('modules.modal_close_window_warning_user.2')}</h3>
      </div>
      <p className="route-content_loyalty-modal-text">
        {t('modules.modal_close_window_warning_user.3')}&nbsp;{t('modules.modal_close_window_warning_user.4')}&nbsp;
        {t('modules.modal_close_window_warning_user.5')}&nbsp;{t('modules.modal_close_window_warning_user.6')}
      </p>
    </Modal>
  );
}

export default ModalCloseWindowWarning;
