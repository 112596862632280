import React from 'react';
import SearchUsers from 'common/SearchUsers/SearchUsers';

import ApolloClient from 'apollo-client';
import { withApollo } from 'react-apollo';

import { Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { SegmentData, SegmentsPersonDescriptor, User } from '../../model/segmentsModel';
import { Pagination } from 'utils/commonTypes';
import { useTranslation } from 'react-i18next';

const TableFormComponent = (props: {
  selectedSegment?: SegmentData;
  wayToAddUsers: string;
  isSegmentDynamic: boolean;
  pagination: Pagination;
  onPageChange: () => void;
  apolloClient: ApolloClient<any>;
  onChange?: (value: SegmentsPersonDescriptor[]) => void;
  value?: SegmentsPersonDescriptor[];
}) => {
  const { t } = useTranslation();
  const { Column } = Table;

  function deleteUser(id: string) {
    if (props.value) {
      props.onChange && props.onChange([...props.value?.filter((u: SegmentsPersonDescriptor) => u.id !== id)]);
    }
  }

  function addUser(value: User) {
    const newUser = {
      id: value.userId,
      person: {
        name: value.name,
        surname: value.surname,
        patronymic: value.patronymic,
        phone: value.login,
        email: value.email,
      },
    };
    props.onChange && props.onChange([newUser, ...(props.value || [])]);
  }

  return (
    <>
      {props.wayToAddUsers === 'search' && <SearchUsers onSelect={addUser} />}

      <Table
        dataSource={props.value}
        size="middle"
        className="segments__segment-table"
        rowKey={(record) => record.id}
        pagination={{
          total: props.value?.length,
          onChange: props.onPageChange,
          current: props.pagination.page,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
      >
        <Column
          title={t('modules.table_form_component.0')}
          key="id"
          align="left"
          className="segments__segment-table-column segments__segment-table-column_name"
          render={(usersArr) =>
            usersArr.person &&
            `${usersArr.person.surname.toString('ru')} ${usersArr.person.name.toString('ru')} ${
              usersArr.person.patronymic?.toString('ru') || ''
            }`
          }
        />
        <Column
          title={t('modules.table_form_component.1')}
          key="person.phone"
          align="left"
          className="segments__segment-table-column segments__segment-table-column_phone"
          render={(userArr) => userArr.person.phone}
        />
        <Column
          title={t('modules.table_form_component.2')}
          key="person.email"
          align="left"
          className="segments__segment-table-column"
          render={(userArr) => userArr.person.email}
        />
        <Column
          title=""
          className="segments__segment-table-column"
          render={(usersArr) =>
            props.isSegmentDynamic ? null : (
              <PlusOutlined
                title={t('modules.table_form_component.3')!}
                className="segments__segment-user-item-icon"
                onClick={() => {
                  deleteUser(usersArr.id);
                }}
              />
            )
          }
        />
      </Table>
    </>
  );
};

export default withApollo(TableFormComponent);
