import { IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';

import { withProps } from 'recompose';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';

import UserList from './Users';
import Segments from './Users/components/Segments/Segments';
import { t } from 'i18next';

export class ModuleUsers implements Module {
  private app: IApp;
  private component: any; //TODO
  private reducers: Array<{ name: string; reducer: any }>;
  private routeContainer: IRouteContainer;
  private menuContainer: ISidebar;

  constructor(app: IApp, routeContainer: IRouteContainer, menuContainer: ISidebar, authClient: AuthClient) {
    this.component = {
      UserList,
      Segments: withProps({
        authClient: authClient // TODO: check if it is necessary
      })(Segments)
    };
    this.reducers = [
      {
        name: 'tickets',
        reducer: null
      }
    ];

    this.app = app;
    this.menuContainer = menuContainer;
    this.routeContainer = routeContainer;

    this.routeContainer.addRoute({
      path: '/admin/users/segments',
      component: this.component.Segments
    });

    this.routeContainer.addRoute({
      path: '/admin/users/user-list',
      component: this.component.UserList
    });

    const userList = new RouteAction({
      title: t('modules.user_index.0'),
      name: 'user-list',
      order: 2.1,
      enabled: true,
      visible: true,
      route: 'admin/users/user-list',
      localizationKey: 'user_list'
    });

    const segments = new RouteAction({
      title: t('modules.user_index.1'),
      name: 'segments',
      order: 2.2,
      enabled: true,
      visible: true,
      route: 'admin/users/segments',
      localizationKey: 'segments'
    });

    // this.reducers.map((reducer: any) => this.app.addReducer(reducer));
    const Users = new RouteAction({
      title: t('modules.user_index.2'),
      icon: 'team',
      name: 'users',
      order: 2,
      enabled: true,
      visible: true,
      subitems: [userList, segments],
      route: 'admin/users',
      permission: 'ACCESS_USERS',
      localizationKey: 'users'
    });

    this.menuContainer.addLink(Users);
  }

  start() {
  }
}
