import React from 'react';
import styles from './ExpandedRows.module.scss';
import { Table } from 'antd';

import { UsersListPromocode } from '../../../model/discountsModel';
import formatUseCoupons from '../../../utils/formatUseCoupons';
import { useTranslation } from 'react-i18next';

const ExpandedRows = (props: { data: UsersListPromocode[] }) => {
  const { Column } = Table;
  const { t } = useTranslation();
  return (
    <div className={styles.expandedRowsWrapper}>
      <Table dataSource={props.data} rowKey="value" className={styles.expandedRows} pagination={false}>
        <Column
          title={t('modules.expanded_rows.0')}
          key="value"
          align="left"
          render={(usersListPromocode: UsersListPromocode) => {
            return usersListPromocode.value.slice(-5);
          }}
        />
        <Column
          title={t('modules.expanded_rows.1')}
          key="useCounter"
          align="left"
          render={(usersListPromocode: UsersListPromocode) => {
            return formatUseCoupons(usersListPromocode.useCounter, usersListPromocode.descriptor.maxUseCountsPerUser);
          }}
        />
      </Table>
    </div>
  );
};

export default ExpandedRows;
