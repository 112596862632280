import React, { useState } from 'react';
import styles from './UploadFormItem.module.scss';
import avatarPlaceholder from '../../../img/avatar_placeholder.svg';

import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';

import { extensionToFileType } from 'utils/helpers';
import generateError from '../../../utils/generateError';
import { ERROR_MESSAGE_CONTENT, FILE_TYPES, MAX_FILE_SIZE } from '../../../../constants';
import { useTranslation } from 'react-i18next';

function UploadFormItem(props: {
  avatar: string;
  setAvatar: (value: string) => void;
  setPhotoFileId: (value: string) => void;
}) {
  const { t } = useTranslation();
  const [isPhotoUploading, setIsPhotoUploading] = useState<boolean>(false);

  const beforeUpload = (file: UploadFile) => {
    const supportedFileTypes = FILE_TYPES.map((e) => extensionToFileType(e));
    if (!supportedFileTypes.includes(file.type)) {
      generateError(`${ERROR_MESSAGE_CONTENT.fileTypeError} ${FILE_TYPES.join(', ')}`);
      return Upload.LIST_IGNORE;
    }

    const tooBig = file.size! / 1024 / 1024 > MAX_FILE_SIZE;
    if (tooBig) {
      generateError(`${ERROR_MESSAGE_CONTENT.fileSizeError} ${MAX_FILE_SIZE} MB`);
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleUpload = (info: UploadChangeParam) => {
    setIsPhotoUploading(true);

    switch (info.file.status) {
      case 'error': {
        generateError(`${ERROR_MESSAGE_CONTENT.loadPhotoError}`);
        setIsPhotoUploading(false);
        break;
      }

      case 'done': {
        info.fileList.map((file) => {
          if (file.response) {
            let linkServer = window.parametrize('REACT_APP_GRAPHQL_ENDPOINT');

            if (linkServer[linkServer.length - 1] === '/') {
              linkServer = linkServer.slice(0, -1);
            }

            const id: string = file.response.fileIds[0];
            props.setPhotoFileId(id);
            props.setAvatar(id ? `${linkServer}/files/${id}` : avatarPlaceholder);
          }
          return file;
        });
        setIsPhotoUploading(false);
        break;
      }

      case 'removed': {
        props.setAvatar(avatarPlaceholder);
        setIsPhotoUploading(false);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <Form.Item name="photoFileId">
      <div className={styles.uploadPhotoItem}>
        <div className={styles.avatarBlock}>
          <img src={props.avatar} alt={t('modules.upload_form_item.0')!} className={styles.avatar} />
        </div>
        <div className={styles.uploadButtonBlock}>
          <Upload
            action={window.parametrize('REACT_APP_FILE_STORAGE')}
            accept={FILE_TYPES.join(', ')}
            maxCount={1}
            beforeUpload={beforeUpload}
            onChange={handleUpload}
            progress={{
              strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
              },
              strokeWidth: 3,
              format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
            }}
            disabled={isPhotoUploading}
          >
            <Button id="UploadFormItemUploadPhotoButton" type="ghost" size="large" className={styles.uploadButton}>
              <UploadOutlined />
              <span>{t('modules.upload_form_item.1')}</span>
            </Button>
          </Upload>
          <p className={styles.uploadPrompt} style={{ marginTop: '12px' }}>
            100 KB — 15 MB
          </p>
          <p className={styles.uploadPrompt}>100 × 100 px — 5000 × 5000 px</p>
        </div>
      </div>
    </Form.Item>
  );
}

export default UploadFormItem;
