import { t } from 'i18next';

export const ERROR_MESSAGE_STYLE = { maxWidth: '300px', margin: '0 auto' };

export const ERROR_MESSAGE_CONTENT = {
  loadError: `${t('modules.constants.0')}:`,
  createError: `${t('modules.constants.1')}:`,
  updateError: `${t('modules.constants.2')}:`,
  addUsersError: `${t('modules.constants.3')}:`,
};
