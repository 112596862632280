import React, { useState } from 'react';
import { Table, Badge } from 'antd';
import './ExpandedRowRender.scss';

import { UserOrdersList, OrderItem, LoyaltyRules, Product } from '../../../../../../model/usersModel';
import { formatFraction } from 'utils/apiHelpers';
import { useTranslation } from 'react-i18next';

const ExpandedRowRender = (props: { record: UserOrdersList; loyaltyRules?: LoyaltyRules }) => {
  const { t } = useTranslation();
  const { Column } = Table;
  const [columnClass, setColumnClass] = useState<string>('subtable-title__display-none');
  const widthColumnLarge: string = '24%';
  const widthColumnMedium: string = '21%';
  const widthColumnSmall: string = '17%';

  const getPaymentMethod = () => {
    const cash = Number(props.record.additionalData?.amount?.cash);
    const electronic = Number(props.record.additionalData?.amount?.electronic);

    if (props.record.additionalData?.amount !== undefined) {
      if (cash < 1 && electronic < 1) {
        return t('modules.expanded_row_render.get_payment_method.0');
      } else if (cash > 1) {
        return t('modules.expanded_row_render.get_payment_method.1');
      } else if (electronic > 1) {
        return t('modules.expanded_row_render.get_payment_method.2');
      }
    } else {
      return t('modules.expanded_row_render.get_payment_method.3');
    }
  };

  const SubtableTitle = (
    <div className="subtable-title">
      <div className="subtable-title__item" style={{ width: widthColumnLarge }}>
        <div className="subtable-title__text subtable-title__text_additional subtable-title__first-row">
          {t('modules.expanded_row_render.subtable_title.0')}
        </div>
        <div className="subtable-title__text subtable-title__text_main">
          {props.record.createdTime.toLocaleString('ru-Ru', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </div>
      </div>
      {props.record.status === 'UNPAYED' ||
      props.record.status === 'PAYMENT_PROCESSING' ||
      props.record.status === 'CANCELED' ? (
        ''
      ) : (
        <div className="subtable-title__item" style={{ width: widthColumnMedium }}>
          <div className="subtable-title__text subtable-title__text_additional subtable-title__first-row">
            {t('modules.expanded_row_render.subtable_title.1')}
          </div>
          <div className="subtable-title__text subtable-title__text_main">{getPaymentMethod()}</div>
        </div>
      )}
      <div className="subtable-title__item" style={{ width: widthColumnMedium }}>
        <div className="subtable-title__text subtable-title__text_additional subtable-title__first-row">
          {t('modules.expanded_row_render.subtable_title.2')}
        </div>
        <div className="subtable-title__text subtable-title__text_main">
          {props.record.appliedPromocode ? props.record.appliedPromocode : '—'}
        </div>
      </div>
      {props.loyaltyRules?.isActive ? (
        <>
          <div className="subtable-title__item" style={{ width: widthColumnSmall }}>
            <div className="subtable-title__text subtable-title__text_additional subtable-title__first-row">
              {t('modules.expanded_row_render.subtable_title.3')} {props.loyaltyRules?.currencyNameMod50.toLowerCase()}
            </div>
            <div className="subtable-title__text subtable-title__text_main">
              {props.record.additionalData?.loyaltyAccrued
                ? formatFraction(props.record.additionalData?.loyaltyAccrued)
                : '—'}
            </div>
          </div>
          <div className="subtable-title__item" style={{ width: widthColumnSmall }}>
            <div className="subtable-title__text subtable-title__text_additional subtable-title__first-row">
              {t('modules.expanded_row_render.subtable_title.4')} {props.loyaltyRules?.currencyNameMod50.toLowerCase()}
            </div>
            <div className="subtable-title__text subtable-title__text_main">
              {props.record.additionalData?.loyaltyAmount
                ? formatFraction(props.record.additionalData?.loyaltyAmount)
                : '—'}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );

  const PRODUCT_TYPE = {
    [Product.TICKET]: t('modules.expanded_row_render.product_type.0'),
    [Product.SEASON_TICKET]: t('modules.expanded_row_render.product_type.1'),
    [Product.PROLONG_SEASON_TICKET]: t('modules.expanded_row_render.product_type.2'),
    [Product.OUTFIT]: t('modules.expanded_row_render.product_type.3'),
  };

  return (
    <div className="subtable-wrapper">
      <Table
        dataSource={props.record.items}
        pagination={false}
        title={() => SubtableTitle}
        rowKey="id"
        tableLayout="fixed"
        style={{ width: '100%', borderRadius: '10px' }}
      >
        <Column title={t('modules.expanded_row_render.column.0')} dataIndex="title" align="left" width="20%" />
        <Column
          title={t('modules.expanded_row_render.column.1')}
          align="left"
          key="price"
          render={(dataSource: OrderItem) => formatFraction(dataSource.price)}
          width="8%"
        />
        <Column
          title={t('modules.expanded_row_render.column.2')}
          align="left"
          key="priceWithDiscount"
          render={(dataSource: OrderItem) => formatFraction(dataSource.priceWithDiscount)}
          width="8%"
        />
        <Column
          title={t('modules.expanded_row_render.column.3')}
          align="left"
          key="type"
          render={(dataSource: OrderItem) => PRODUCT_TYPE[dataSource.type]}
          width="12%"
        />
        <Column
          title={t('modules.expanded_row_render.column.4')}
          key="status"
          align="left"
          render={(dataSource: OrderItem) => {
            switch (dataSource.status) {
              case 'SOLD':
                return (
                  <span>
                    <Badge color="#03B94E" className="subtable-title__badge" />
                    {t('modules.expanded_row_render.span.0')}
                  </span>
                );
              case 'RESERVED':
                return (
                  <span>
                    <Badge color="#F8A608" className="subtable-title__badge" />
                    {t('modules.expanded_row_render.span.1')}
                  </span>
                );
              case 'REFUNDED': {
                setColumnClass('');
                return (
                  <span>
                    <Badge color="#5C5C5C" className="subtable-title__badge" />
                    {t('modules.expanded_row_render.span.2')}
                  </span>
                );
              }
            }
          }}
          width="16%"
        />
        <Column
          title={t('modules.expanded_row_render.column.5')}
          key="refundTime"
          align="left"
          className={columnClass}
          render={(dataSource: OrderItem) =>
            dataSource?.refundTime !== undefined
              ? dataSource?.refundTime.toLocaleString('ru-Ru', { dateStyle: 'short', timeStyle: 'short' })
              : ''
          }
          width="16%"
        />
        {/* This is not yet */}
        {/*<Column*/}
        {/*  title={t('modules.expanded_row_render.column.6')}*/}
        {/*  // dataIndex='refundTime'*/}
        {/*  align='left'*/}
        {/*  render={() => (*/}
        {/*    <span>*/}
        {/*          <Icon type='plus' style={{transform: 'rotate(45deg)', color: 'red'}}/>*/}
        {/*            ?*/}
        {/*        </span>*/}
        {/*  )}*/}
        {/*  width='16%'*/}
        {/*/>*/}
      </Table>
    </div>
  );
};

export default ExpandedRowRender;
