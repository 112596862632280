//TODO: similar constants are used in discounts/src/constants.js, should they be shared?

import { t } from 'i18next';

export const ERROR_MESSAGE_STYLE = { maxWidth: '300px', margin: '0 auto' };

export const ERROR_MESSAGE_CONTENT = {
  loadRolesError: `${t('modules.constants_users.0')}:`,
  loadPhotoError: t('modules.constants_users.1'),
  userRegistrationError: `${t('modules.constants_users.2')}:`,
  sameNumberError: t('modules.constants_users.3'),
  fileTypeError: t('modules.constants_users.4'),
  fileSizeError: t('modules.constants_users.5'),
  languageError: t('modules.constants_users.6'),
};

export const FORMAT_DATE = 'DD.MM.YYYY';
export const MAX_FILE_SIZE = 15;
export const FILE_TYPES = ['.jpg', '.png', '.jpeg'];
