import { t } from 'i18next';
import { CodesPaginatedResult } from '../model/discountsModel';

export default function formatWhomAvailable(codes: CodesPaginatedResult) {
  const personData = codes.list[0]?.couponUser?.person;
  const user = personData
    ? `${personData?.surname.toString()} ${personData?.name.toString()} ${personData?.patronymic?.toString() || ''}`
    : '';

  switch (codes.total) {
    case 0:
      return t('modules.format_whom_available.0');
    case 1:
      return user;
    default:
      return t('modules.format_whom_available.1');
  }
}
