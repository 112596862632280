import React from 'react';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Col} from 'antd';
import {
  ACC_AccessCategory,
  ACC_AccessCategoryField,
  AdditionalFields,
  RemoteFile
} from '../../../../../model/accreditationModel';
import {LocalizedString} from 'utils/LocalizedString';
import styles from './AdditionalFieldsComponent.module.scss';
import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';
import {useTranslation} from 'react-i18next';
import LocalizedTextInput from 'common/LocalizedTextInput/LocalizedTextInput';
import i18next from 'i18next';

const FormItem = Form.Item;

export default function AdditionalFieldsComponent(props: {
  additionalFields: ACC_AccessCategoryField[] | undefined;
  form: any;
  currentAdditinalFieldsData: AdditionalFields[] | undefined;
  initialAccessCategory: ACC_AccessCategory;
  currentAccessCategory: ACC_AccessCategory;
  setAdditionalFieldsData: (id: string, value?: any) => void;
  remoteFile?: RemoteFile;
}) {
  const { t } = useTranslation();
  const { getFieldDecorator } = props.form;

  return (
    <div>
      {props.currentAdditinalFieldsData &&
        props.currentAdditinalFieldsData.map((data, i: number) => {
          switch (data.field.type) {
            case 'FILE':
              return (
                <FormItem key={data.field.id}>
                  <Col className={styles.name} span={5}>
                    {data.field.title.toString()}
                  </Col>
                  <div className={styles.content}>
                    <Col className={styles.contentValue} span={19}>
                      {props.remoteFile?.fileName}
                    </Col>
                    {getFieldDecorator('upload', {
                      initialValue: 'data.value',

                      rules: [
                        {
                          required: true,
                          message: t('modules.additional_fields_component.get_field_decorator.0'),
                        },
                      ],
                    })(
                      <SingleFileUpload
                        title={t('modules.additional_fields_component.single_file_upload')}
                        onChange={(value) => props.setAdditionalFieldsData(data.field.id, value)}
                        initialValue={data.value}
                        accept={['.doc', '.docx', '.pdf', '.png', '.jpeg', '.jpg']}
                        maxFileSize={15}
                        imageUpload
                      />
                    )}
                  </div>
                </FormItem>
              );
            case 'TEXT':
              return <></>;
            case 'LOCALIZED_TEXT':
              const name: string = data.field.title.toString(i18next.language);
              return (
                <FormItem key={data.field.id}>
                  {getFieldDecorator(data.field.id, {
                    initialValue:
                      data.value.length > 0
                        ? LocalizedString.fromObject(JSON.parse(data.value))
                        : LocalizedString.fromObject({ ru: '', en: '' }),
                    rules: [
                      {
                        required: true,
                        message: t('modules.additional_fields_component.get_field_decorator.1'),
                      },
                    ],
                  })(
                    <div className={styles.localizedTextWrapper}>
                      <h3>{name}</h3>
                      <LocalizedTextInput
                        value={LocalizedString.fromObject(data.value)}
                        labelCustomization={(langCode) => `${langCode}`}
                        key={data.field.id}
                        inputName={name}
                        onChange={(value) => props.setAdditionalFieldsData(data.field.id, value)}
                        borderRadius="2px"
                        direction="row"
                        labelPosition="left"
                        justify="space-between"
                        wrapperClasses={styles.customWrapper}
                      />
                    </div>
                  )}
                </FormItem>
              );
            default:
              return null;
          }
        })}
    </div>
  );
}
