import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../Loyalty.scss';
import './ModalHoverLegalityDocumentation.scss';

function ModalHoverLegalityDocumentation(props: any) {
  const { t } = useTranslation();
  return (
    <div className="route-content_loyalty-modal-hover-legality-documentation">
      <p className="route-content_loyalty-modal-hover-text">
        {t('modules.modal_hover_legality_documentation.0')}&nbsp;{t('modules.modal_hover_legality_documentation.1')}
      </p>
      <p className="route-content_loyalty-modal-hover-text">
        {t('modules.modal_hover_legality_documentation.2')}&nbsp;{t('modules.modal_hover_legality_documentation.3')}
        &nbsp;{t('modules.modal_hover_legality_documentation.4')}&nbsp;
        {t('modules.modal_hover_legality_documentation.5')}
      </p>
    </div>
  );
}

export default ModalHoverLegalityDocumentation;
