import React from 'react';
import { useHistory } from 'react-router-dom';
import './SegmentForm.scss';
import RouteLeavingGuard from '../RouteLeavingGuard/RouteLeavingGuard';
import iconStaticSegment from '../../images/icon-static-segment-grey.svg';
import iconDynamicSegment from '../../images/icon-dynamic-segment-grey.svg';

import { LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Form, Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const SegmentForm = (props: {
  selectedSegmentTitle: string;
  isSegmentDynamic: boolean;
  isCreatingSegment: boolean;
  isValuesChanged: boolean;
  loadingPhones: boolean;
  wayToAddUsers: string;
  setWayToAddUsers: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  let history = useHistory();

  const InputSql = (
    <>
      <Form.Item
        name="sql"
        id="sql"
        className="segments__segment-input"
        rules={[{ required: true, message: t('modules.segment_form.form_item.0')! }]}
      >
        <TextArea
          placeholder={t('modules.segment_form.textarea.0')!}
          className="segments__segment-textarea"
          autoSize={true}
          autoComplete="off"
          rows={1}
        />
      </Form.Item>
      <p className="segments__segment-input-description">
        {t('modules.segment_form.p.0')}&nbsp;{t('modules.segment_form.p.1')}&nbsp;{t('modules.segment_form.p.2')}
      </p>
    </>
  );

  const InputPhonesList = (
    <Form.Item name="phonesList" id="phonesList" className="segments__segment-input">
      <TextArea
        placeholder={t('modules.segment_form.textarea.1')!}
        className="segments__segment-textarea"
        autoSize={true}
        autoComplete="off"
        spellCheck={false}
        rows={1}
      />
    </Form.Item>
  );

  const INPUT_MAP: any = {
    search: null,
    sql: InputSql,
    phonesList: InputPhonesList,
  };

  const SegmentType = () => {
    const segmentTypeIcon = props.isSegmentDynamic ? iconDynamicSegment : iconStaticSegment;
    const segmentTypeClass = props.isSegmentDynamic
      ? 'segments__segment-type-icon_dynamic'
      : 'segments__segment-type-icon_static';
    const segmentTypeTitle = props.isSegmentDynamic
      ? t('modules.segment_form.segment_type_title.0')
      : t('modules.segment_form.segment_type_title.1');

    return (
      <div className="segments__segment-description-block">
        <img
          src={segmentTypeIcon}
          className={`segments__segment-type-icon ${segmentTypeClass}`}
          alt={segmentTypeTitle}
        />
        <p className="segments__segment-type-description">{segmentTypeTitle}</p>
      </div>
    );
  };

  return (
    <>
      <h2 className="segments__segment-title">{props.selectedSegmentTitle}</h2>

      {props.isCreatingSegment ? null : <SegmentType />}

      <p className="segments__segment-input-title">{t('modules.segment_form.p.3')}</p>
      <Form.Item
        name="title"
        id="title"
        className="segments__segment-input"
        rules={[{ required: true, message: t('modules.segment_form.form_item.1')! }]}
      >
        <Input placeholder={t('modules.segment_form.input')!} size="large" autoComplete="off" spellCheck={false} />
      </Form.Item>

      <p className="segments__segment-input-title">{t('modules.segment_form.p.4')}</p>
      <Form.Item name="comment" id="comment" className="segments__segment-input">
        <TextArea
          placeholder={t('modules.segment_form.textarea.2')!}
          className="segments__segment-textarea"
          autoSize={true}
          rows={1}
          autoComplete="off"
          spellCheck={false}
        />
      </Form.Item>

      <p className="segments__segment-input-title">{t('modules.segment_form.p.5')}</p>
      {props.isSegmentDynamic ? null : (
        <div className="segments__segment-radios-wrapper">
          <Form.Item name="radios" id="radios">
            <Radio.Group className="segments__segment-radios">
              <Radio
                className="segments__segment-radio"
                value={'search'}
                onChange={() => props.setWayToAddUsers('search')}
              >
                {t('modules.segment_form.radio.0')}
              </Radio>
              {props.isCreatingSegment ? (
                <Radio className="segments__segment-radio" value={'sql'} onChange={() => props.setWayToAddUsers('sql')}>
                  {t('modules.segment_form.radio.1')}
                </Radio>
              ) : null}
              <Radio
                className="segments__segment-radio"
                value={'phonesList'}
                onChange={() => props.setWayToAddUsers('phonesList')}
              >
                {t('modules.segment_form.radio.2')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          {props.loadingPhones ? <LoadingOutlined className="segments__segment-loading-phones-icon" /> : null}
        </div>
      )}

      {INPUT_MAP[props.wayToAddUsers]}

      <RouteLeavingGuard
        when={props.isValuesChanged}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return true;
        }}
      />
    </>
  );
};

export default SegmentForm;
