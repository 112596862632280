import React from 'react';
import styles from './AccreditationStatus.module.scss';
import { AccreditationRequestStatus } from '../../model/accreditationModel';
import { withTranslation } from 'react-i18next';

const accreditationStatusToString = (status: AccreditationRequestStatus, t: any) => {
  switch (status) {
    case AccreditationRequestStatus.PENDING:
      return t('modules.accreditation_status.0');
    case AccreditationRequestStatus.REJECTED:
      return t('modules.accreditation_status.1');
    case AccreditationRequestStatus.ACCEPTED:
      return '';
  }
};

function AccreditationStatus(props: { status: AccreditationRequestStatus; className?: string; t?: any }) {
  return (
    <div>
      {props.status !== AccreditationRequestStatus.ACCEPTED && (
        <div
          className={`${styles.status} ${props.className}`}
          style={{ color: props.status === AccreditationRequestStatus.PENDING ? '#03B94E' : '#FF4747' }}
        >
          {accreditationStatusToString(props.status, props.t)}
        </div>
      )}
    </div>
  );
}

export default withTranslation()(AccreditationStatus);
