import accredList from './AccreditationList/AccreditationList';
import accreditationModal from './AccreditationModal/AccreditationModal';

import { Action, IApp, IRouteContainer, ISidebar, Module, RouteAction } from '../../common/src';
import { t } from 'i18next';

export class ModuleAccreditation implements Module {
  private component: any;

  constructor(private app: IApp, private routeContainer: IRouteContainer, private menuContainer: ISidebar) {
    this.component = {
      accredList,
      accreditationModal
    };

    this.routeContainer.addRoute({
      path: '/admin/accreditation',
      component: this.component.accredList
    });

    this.routeContainer.addRoute({
      path: '/admin/accreditation/:id',
      component: this.component.accreditationModal
    });

    const accreditation = new RouteAction({
      title: t('modules.accreditation_index.0'),
      name: 'accreditation-list',
      order: 10.1,
      enabled: true,
      visible: true,
      route: 'admin/accreditation',
      localizationKey: 'accreditation'
    });

    const accredLink = new Action({
      title: t('modules.accreditation_index.1'),
      icon: 'file-protect',
      name: 'accreditation',
      order: 10,
      enabled: true,
      visible: true,
      subitems: [accreditation],
      permission: 'ACCESS_ACCREDITATION',
      localizationKey: 'accreditation'
    });

    this.menuContainer.addLink(accredLink);
  }

  start() {
  }
}
