import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { DownOutlined, LoadingOutlined, RightOutlined, StopOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal } from 'antd';
import {
  GET_CURRENT_SEASON,
  GET_MATCHES,
  GET_SEASON_SUM,
  GET_SEASON_TICKETS,
  GET_SEASONS,
  GET_SUM,
} from '../../graphql';
import TicketsTable from './TicketsTable';
import MapContainer from './MapContainer';
import FilterModal from './FilterModal';
import i18next, { t } from 'i18next';
import { LocalizedString, localizedStringIsNotEmpty } from 'utils/LocalizedString';
import checkTypeName, { RemoteTypeNames } from '../../utils/checkTypeName';

const TicketsComponent = (props) => {
  const [stateProps, setState] = useState({
    displayList: true,
    matchesCount: 10,
    isLoaded: false,
    isUpdated: true,
    selectedEvent: {},
    sumObj: {},
    isSeasonFilterModalVisible: false,
    filter: {},
    count: 0,
    paramsFilter: {},
  });

  const getMonth = (index, inNumber) => {
    const monthArray = [
      t('modules.tickets_component.month_array.0'),
      t('modules.tickets_component.month_array.1'),
      t('modules.tickets_component.month_array.2'),
      t('modules.tickets_component.month_array.3'),
      t('modules.tickets_component.month_array.4'),
      t('modules.tickets_component.month_array.5'),
      t('modules.tickets_component.month_array.6'),
      t('modules.tickets_component.month_array.7'),
      t('modules.tickets_component.month_array.8'),
      t('modules.tickets_component.month_array.9'),
      t('modules.tickets_component.month_array.10'),
      t('modules.tickets_component.month_array.11'),
    ];
    return inNumber ? (index + 1 < 10 ? '0' + (index + 1) : index + 1) : monthArray[index];
  };
  const numberWithSpace = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } else {
      return 0;
    }
  };
  const getTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return ' ' + (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  };
  const onFilterModalCancel = () => {
    setState({
      ...stateProps,
      isfilterModalVisible: false,
    });
  };
  const onFilterModalApply = (filter) => {
    setState({
      ...stateProps,
      paramsFilter: filter,
      isfilterModalVisible: false,
      isLoaded: false,
    });
  };
  const getEventTitle = (event) => {
    if (event.isEmpty) {
      return (
        <div className="sales__tickets__match-info">
          <div className="sales__tickets__match-info__team">{t('modules.tickets_component.get_event_title')}</div>
        </div>
      );
    }
    if (checkTypeName(event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR)) {
      return (
        <div className="sales__tickets__match-info">
          <div className="sales__tickets__match-info__team">{pathOr('', ['title'], event)}</div>
        </div>
      );
    }
    const team2title = localizedStringIsNotEmpty(event.team2)
      ? LocalizedString.fromObject(event.team2.title).toString(i18next.language)
      : '';
    const team1title = localizedStringIsNotEmpty(event.team1)
      ? LocalizedString.fromObject(event.team1.title).toString(i18next.language)
      : '';

    const startTime = event.startTime ? event.startTime : 0;
    const date = new Date(startTime);
    const day = date.getDate();
    return (
      <div className="sales__tickets__match-info">
        <div className="sales__tickets__match-info__team">{`${team1title} vs ${team2title},`} &nbsp;</div>
        <div className="sales__tickets__match-info__date">
          {(day < 10 ? '0' + day : day) +
            ' ' +
            getMonth(date.getMonth(), false) +
            ' ' +
            date.getFullYear() +
            ', ' +
            getTime(date)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const init = async () => {
      const seasonsRes = await props.client.query({
        query: GET_SEASONS,
        fetchPolicy: 'no-cache',
      });

      const seasonsList = pathOr([], ['data', 'season', 'getList', 'list'], seasonsRes);
      const filteredseasonsList = seasonsList.map((item) => {
        const duration = item.startDate.split('-')[0] + '-' + item.endDate.split('-')[0];
        return {
          id: item.id,
          duration,
        };
      });

      const currentSeasonRes = await props.client.query({
        query: GET_CURRENT_SEASON,
        fetchPolicy: 'no-cache',
      });

      const currentSeasonId = pathOr('', ['data', 'season', 'getCurrent', 'id'], currentSeasonRes);

      const matchesResult = await props.client.query({
        query: GET_MATCHES,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            hidden: null,
            seasonIds: [stateProps.filter.seasonId ? stateProps.filter.seasonId : currentSeasonId],
            pagination: {
              limit: stateProps.matchesCount,
              page: 0,
            },
          },
        },
      });
      const seasonTicketsRes = await props.client.query({
        query: GET_SEASON_TICKETS,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            hidden: null,
            seasonIds: [stateProps.filter.seasonId ? stateProps.filter.seasonId : currentSeasonId],
          },
        },
      });
      const seasonTicketsList = pathOr([], ['data', 'seasonTicketDescriptor', 'getList', 'list'], seasonTicketsRes);
      const matchList = pathOr([], ['data', 'matchLocalised', 'getList', 'list'], matchesResult);
      const selectedEvent = stateProps.selectedEvent;

      let now = new Date();
      let upcomingMatches = matchList
        .filter((match) => {
          const startTime = match.startTime ? match.startTime : 0;
          const date = new Date(startTime);
          date.setHours(date.getHours() + 2);
          if (date < now) return false;
          else return true;
        })
        .sort((first, second) => {
          const firstDate = new Date(first.startTime ? first.startTime : 0);
          const secondDate = new Date(second.startTime ? second.startTime : 0);
          if (firstDate > secondDate) {
            return 1;
          }
          if (firstDate < secondDate) {
            return -1;
          }
          return 0;
        });

      let currentEvent = !isEmpty(stateProps.selectedEvent)
        ? stateProps.selectedEvent
        : upcomingMatches.length > 0
          ? upcomingMatches[0]
          : matchList[0]
            ? matchList[0]
            : {isEmpty: true};

      let sumRes;
      let sumObj = {};
      if (!isEmpty(selectedEvent) || !isEmpty(currentEvent)) {
        const event = !isEmpty(selectedEvent) ? selectedEvent : currentEvent;
        try {
          sumRes = await props.client.query({
            query: checkTypeName(event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR) ? GET_SEASON_SUM : GET_SUM,
            fetchPolicy: 'no-cache',
            variables: {
              filter:
                checkTypeName(event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR)
                  ? {
                    seasonTicketDescriptorIds: event.id,
                    statuses: ['SOLD'],
                  }
                  : {
                    eventIds: event.id,
                    status: ['SOLD'],
                  },
            },
          });
        } catch (e) {
          console.error(e);
          sumRes = {};
        }

        sumObj = pathOr(
          {},
          [
            'data',
            checkTypeName(event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR) ? 'seasonTicket' : 'ticket',
            'summary',
            'totals',
          ],
          sumRes
        );
      }
      const filteredList = matchList.map((item) => {
        return (
          <div
            key={item.id}
            onClick={() => {
              setState({
                ...stateProps,
                filteredList: filteredList,
                selectedEvent: item,
                isLoaded: false,
                dropdownVisible: false,
              });
            }}
          >
            <div className="sales__tickets__dropdown__item">
              {getEventTitle(item)}
              {item.status === 'ACTIVE' && (
                <div className="sales__tickets__dropdown__status">{t('modules.tickets_component.use_effect')}</div>
              )}
            </div>
          </div>
        );
      });
      const filteredSeasonTicketsList = seasonTicketsList.map((item) => {
        return (
          <div
            key={item.id}
            onClick={() => {
              setState({
                ...stateProps,
                filteredSeasonTicketsList: filteredSeasonTicketsList,
                selectedEvent: item,
                isLoaded: false,
                dropdownVisible: false,
              });
            }}
            className="sales__tickets__dropdown__season-ticket-title"
          >
            {pathOr('', ['title'], item)}
          </div>
        );
      });
      setState({
        ...stateProps,
        filteredList,
        filteredSeasonTicketsList,
        selectedEvent: currentEvent,
        filteredseasonsList,
        sumObj: {
          count: sumObj.count,
          sumWithDiscount: Math.floor(parseInt(sumObj.sumDiscount)),
        },
        isLoaded: true,
        isUpdated: false,
        count: stateProps.count + 1,
      });
    };
    if (!stateProps.isLoaded || stateProps.isUpdated) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded, stateProps.isUpdated]);

  return (
    <>
      <h1>{t('modules.tickets_component.h1')}</h1>
      <div className="route-content">
        <div className="sales__tickets">
          <div className="permissions__right-container__body__switcher">
            <div
              className={
                stateProps.displayList
                  ? 'permissions__right-container__body__switcher__item__active'
                  : 'permissions__right-container__body__switcher__item'
              }
              onClick={() => {
                setState({
                  ...stateProps,
                  displayList: true,
                });
              }}
              style={{
                borderRadius: '4px 0 0 4px',
              }}
            >
              {t('modules.tickets_component.div.list')}
            </div>
            <div
              className={
                stateProps.displayList
                  ? 'permissions__right-container__body__switcher__item'
                  : 'permissions__right-container__body__switcher__item__active'
              }
              style={{
                borderRadius: '0 4px 4px 0',
              }}
              onClick={() => {
                setState({
                  ...stateProps,
                  displayList: false,
                  isLoaded: false,
                });
              }}
            >
              {t('modules.tickets_component.div.map')}
            </div>
          </div>
          <div
            className="sales__tickets__menu"
            style={{
              justifyContent: stateProps.displayList ? '' : 'space-between',
            }}
          >
            <div className="sales__tickets__dropdown-container">
              <Dropdown
                className="sales__tickets__dropdown"
                overlay={
                  <Menu>
                    <div
                      className="sales__tickets__dropdown__filter-season-button"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          dropdownVisible: false,
                          isSeasonFilterModalVisible: true,
                        });
                      }}
                    >
                      <RightOutlined className="sales__tickets__dropdown__filter-season-button__icon" />
                      <div>{t('modules.tickets_component.div.change_season')}</div>
                    </div>
                    <div className="sales__tickets__dropdown__title">{t('modules.tickets_component.div.season_tickets')}</div>
                    {isEmpty(stateProps.filteredSeasonTicketsList) ? (
                      <div className="sales__tickets__dropdown__matches__placeholder">
                        {t('modules.tickets_component.div.no_season_tickets')}
                      </div>
                    ) : (
                      stateProps.filteredSeasonTicketsList
                    )}
                    <div className="sales__tickets__dropdown__title"> {t('modules.tickets_component.div.matches')} </div>
                    <div className="sales__tickets__dropdown__matches">
                      {isEmpty(stateProps.filteredList) ? (
                        <div className="sales__tickets__dropdown__matches__placeholder">
                          {t('modules.tickets_component.div.no_matches')}
                        </div>
                      ) : (
                        stateProps.filteredList
                      )}
                    </div>
                    <div
                      className="sales__tickets__dropdown__load-more"
                      onClick={() => {
                        setState({
                          ...stateProps,
                          matchesCount: stateProps.matchesCount + 10,
                          isUpdated: true,
                        });
                      }}
                    >
                      {stateProps.isUpdated ? <LoadingOutlined /> : t('modules.tickets_component.div.more_matches')}
                    </div>
                  </Menu>
                }
                trigger={['click']}
                disabled={!stateProps.isLoaded}
                visible={stateProps.dropdownVisible}
                onClick={() => {
                  setState({
                    ...stateProps,
                    dropdownVisible: true,
                  });
                }}
                onVisibleChange={(ev) => {
                  setState({
                    ...stateProps,
                    dropdownVisible: ev,
                  });
                }}
              >
                <div>
                  {stateProps.isLoaded ? (
                    getEventTitle(stateProps.selectedEvent)
                  ) : (
                    <div className="sales__tickets__match-info">
                      <div className="sales__tickets__match-info__date">{t('modules.tickets_component.div.loading')}</div>
                    </div>
                  )}{' '}
                  <DownOutlined className="sales__tickets__dropdown__icon" />
                </div>
              </Dropdown>
            </div>
            {stateProps.displayList ? (
              <div
                className="sales__tickets__list-filter"
                onClick={() => {
                  if (stateProps.isLoaded) {
                    setState({
                      ...stateProps,
                      isfilterModalVisible: true,
                    });
                  }
                }}
              >
                {t('modules.tickets_component.div.filters')}
              </div>
            ) : (
              ''
            )}
            {stateProps.isLoaded && !stateProps.displayList ? (
              <div className="sales__tickets__statistics">
                {numberWithSpace(stateProps.sumObj.sumWithDiscount)}{' '}
                <span>{t('modules.tickets_component.span.revenue')}: &nbsp;</span> {numberWithSpace(stateProps.sumObj.count)}{' '}
                &nbsp;&nbsp;&nbsp; <span>{t('modules.tickets_component.span.tickets')}: &nbsp;</span>
              </div>
            ) : (
              ''
            )}
          </div>
          {stateProps.isLoaded && stateProps.displayList ? (
            <div className="sales__tickets__statistics">
              {numberWithSpace(stateProps.sumObj.sumWithDiscount)}{' '}
              <span>{t('modules.tickets_component.span.revenue')}: &nbsp;</span> {numberWithSpace(stateProps.sumObj.count)}{' '}
              &nbsp;&nbsp;&nbsp; <span>{t('modules.tickets_component.span.tickets')}: &nbsp;</span>
            </div>
          ) : (
            ''
          )}
          {stateProps.displayList ? (
            <div className="sales__tickets__list-table">
              {stateProps.isLoaded && !stateProps.selectedEvent.isEmpty ? (
                <TicketsTable
                  filter={stateProps.paramsFilter}
                  authClient={props.authClient}
                  getMonth={getMonth}
                  getTime={getTime}
                  client={props.client}
                  event={stateProps.isLoaded ? stateProps.selectedEvent : 1}
                />
              ) : stateProps.selectedEvent.isEmpty ? (
                <div className="with-preloader__map">
                  <StopOutlined />
                </div>
              ) : (
                <div className="with-preloader__map">
                  <LoadingOutlined />
                </div>
              )}
            </div>
          ) : (
            <div className="sales__tickets__map-container">
              {stateProps.isLoaded && !stateProps.selectedEvent.isEmpty ? (
                <MapContainer event={stateProps.selectedEvent} client={props.client} authClient={props.authClient} />
              ) : (
                <div className="with-preloader__map">
                  <LoadingOutlined />
                </div>
              )}
            </div>
          )}
          <Modal
            visible={stateProps.isSeasonFilterModalVisible}
            centered
            title={t('modules.tickets_component.modal')}
            onCancel={() =>
              setState({
                ...stateProps,
                isSeasonFilterModalVisible: false,
              })
            }
            width={725}
            footer={false}
            className="sales__tickets__season-filter-modal"
          >
            <div className="sales__tickets__season-filter-modal__container">
              {stateProps.filteredseasonsList
                ? stateProps.filteredseasonsList.map((item) => (
                  <div
                    key={`modal-item-${item.id}`}
                    className="sales__tickets__season-filter-modal__item"
                    onClick={() => {
                      setState({
                        ...stateProps,
                        isSeasonFilterModalVisible: false,
                        isLoaded: false,
                        selectedEvent: {},
                        filter: {
                          ...stateProps.filter,
                          seasonId: item.id,
                        },
                      });
                    }}
                  >
                    {t('modules.tickets_component.div.season')} {item.duration}
                  </div>
                ))
                : ''}
            </div>
          </Modal>
          <FilterModal
            onCancel={onFilterModalCancel}
            isfilterModalVisible={stateProps.isfilterModalVisible}
            onFilterModalApply={onFilterModalApply}
          />
        </div>
      </div>
    </>
  );
};

export default TicketsComponent;
