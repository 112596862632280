import React, {useState} from 'react';
import styles from './AccreditationCategory.module.scss';
import {AccreditationFilterInput, AccreditationRequestStatus} from '../../model/accreditationModel';
import {allStatuses} from '../../../../../constants';
import {useTranslation} from 'react-i18next';

export default function AccreditationCategory(props: {
  onFilterChange: (newFilterFields: AccreditationFilterInput) => void;
  initialCategories: AccreditationRequestStatus[];
}) {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState<AccreditationRequestStatus[]>(props.initialCategories);

  function handleClick(e: any): void {
    const categoryId = e.target.getAttribute('data-categoryid');
    const categories = categoryId === 'ALL' ? allStatuses : [categoryId];
    props.onFilterChange({requestStatuses: categories});
    setActiveCategory(categories);
  }

  function createStyles(category: AccreditationRequestStatus[]): string {
    if (category.length !== activeCategory.length) {
      return `${styles.category__listBtn}`;
    }
    return [
      styles.category__listBtn,
      category.every((item, index) => item === activeCategory[index]) && styles.category__listBtn_active,
    ].join(' ');
  }
  const accreditationsFilterData = [
    {
      title: t('modules.constants_app.14'),
      id: 'ALL',
      categories: allStatuses,
    },
    {
      title: t('modules.constants_app.15'),
      id: 'PENDING',
      categories: [AccreditationRequestStatus.PENDING],
    },
    {
      title: t('modules.constants_app.16'),
      id: 'ACCEPTED',
      categories: [AccreditationRequestStatus.ACCEPTED],
    },
    {
      title: t('modules.constants_app.17'),
      id: 'REJECTED',
      categories: [AccreditationRequestStatus.REJECTED],
    },
  ];
  return (
    <div className={styles.category}>
      <ul className={styles.category__list}>
        {accreditationsFilterData.map((category) => {
          return (
            <li key={category.id} className={styles.category__listItem}>
              <button id={`button-${category.id}`} onClick={handleClick} className={createStyles(category.categories)}
                      data-categoryid={category.id}>
                {category.title}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
