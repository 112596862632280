import React from 'react';
import './ModalSaveError.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

function ModalSaveError(props: { show: boolean; onOk: () => void }) {
  const { t } = useTranslation();
  const modalTitle = (
    <div className="modal-save-error__title-block">
      <ExclamationCircleOutlined className="modal-save-error__exclamation-circle" />
      <h3 className="modal-save-error__title">{t('modules.modal_save_error_user.0')}</h3>
    </div>
  );
  const modalFooter = (
    <Button id="ModalSaveErrorOkButton" key="okButton" className="modal-save-error__ok-button" onClick={props.onOk}>
      {t('modules.modal_save_error_user.1')}
    </Button>
  );

  return (
    <Modal visible={props.show} title={modalTitle} footer={modalFooter} closable={false} width={430}>
      <p className="modal-save-error__text">
        {t('modules.modal_save_error_user.2')}&nbsp;{t('modules.modal_save_error_user.3')}
      </p>
      <p className="modal-save-error__text">{t('modules.modal_save_error_user.4')}</p>
    </Modal>
  );
}

export default ModalSaveError;
