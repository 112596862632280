import React, { useState, useEffect } from 'react';
import './Discounts.scss';
import CouponsTable from '../CouponsTable/CouponsTable';
import DiscountsFormModal from '../DiscountsFormModal/DiscountsFormModal';
import Loader from 'common/Loader/Loader';
import { DiscountsRepository } from '../../model/DiscountsRepository';
import { CouponsList } from '../../model/discountsModel';

import { Button, Input, message, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDidMount } from 'utils/apiHelpers';
import { Pagination } from 'utils/commonTypes';
import { ERROR_MESSAGE_TIME_DURATION } from 'constants.js';
import { ERROR_MESSAGE_STYLE, ERROR_MESSAGE_CONTENT } from '../../../constants';

import { PromocodeType, Coupon } from '../../model/discountsModel';
import useDebounce from '../../utils/useDebounce';
import { useLoadingMessage } from '../../utils/useLoadingMessage';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../../../users/src/Users/components/SearchInput';
import styles from '../../../../../promocode/src/PromoList/components/PromoBody/components/input.module.scss';

function Discounts(props: { discountsRepository: DiscountsRepository }) {
  const { TabPane } = Tabs;
  const { t } = useTranslation();

  const defaultPagination = { limit: 10, page: 0 };
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCouponsListLoading, setIsCouponsListLoading] = useState<boolean>(false);
  const [couponsList, setCouponsList] = useState<CouponsList>();
  const [tabActiveKey, setTabActiveKey] = useState<PromocodeType>(PromocodeType.PERSONAL_COUPON);
  const [searchRequest, setSearchRequest] = useState<string>('');
  const [couponData, setCouponData] = useState<Coupon>();
  const [loadingMessage, setLoadingMessage] = useLoadingMessage(t('modules.discounts.use_loading_message'));
  const [isDiscountsFormModalOpened, setIsDiscountsFormModalOpened] = useState<boolean>(false);

  async function getCouponsList() {
    try {
      setIsCouponsListLoading(true);
      setCouponsList(await props.discountsRepository.getCouponsList(pagination, searchRequest));
    } catch (e) {
      console.log('getCouponsList error', e);
      message.error({
        content: `${ERROR_MESSAGE_CONTENT.loadError} ${e}`,
        duration: ERROR_MESSAGE_TIME_DURATION,
        style: ERROR_MESSAGE_STYLE,
      });
    } finally {
      setIsCouponsListLoading(false);
    }
  }

  useDidMount(() => {
    getCouponsList();
  });

  useEffect(() => {
    if (!isDiscountsFormModalOpened) {
      setCouponData(undefined);
    }
  }, [isDiscountsFormModalOpened]);

  useEffect(() => {
    setLoadingMessage(isLoading);
  }, [isLoading]);

  // Coupon search by name with one second delay
  const debouncedSearchTerm = useDebounce<string>(searchRequest, 1000);

  useEffect(() => {
    setPagination(defaultPagination);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (tabActiveKey === PromocodeType.PERSONAL_COUPON) {
      getCouponsList();
    } else if (tabActiveKey === PromocodeType.PROMOCODE) {
      // getPromocodesList(pagination);
    }
  }, [pagination, tabActiveKey]);

  // Pagination
  function createNewPagination(page?: number): Pagination {
    return {
      ...pagination,
      page: page ? page - 1 : 0,
    };
  }

  function onPageChange(page?: number) {
    const newPagination = createNewPagination(page);
    setPagination(newPagination);
  }

  return (
    <section className="discounts">
      <div className="discounts__header-container">
        <h1 className="discounts__header-title">{t('modules.discounts.h1')}</h1>
        <Button
          id="DiscountsAddDiscountButton"
          type="ghost"
          className="discounts__header-button"
          size="large"
          onClick={() => setIsDiscountsFormModalOpened(true)}
        >
          <PlusOutlined className="discounts__header-button-icon" />
          <span>{t('modules.discounts.span')}</span>
        </Button>
      </div>

      <div className="discounts__main-content">
        <div className="discounts__search-input-wrapper">
          <SearchInput
            handleSearchChange={(value: string) => setSearchRequest(value)}
            inputStyle={{
              width: '50%',
              height: '40px',
              margin: '0 0 16px 0',
            }}
            iconStyle={{
              fontSize: '18px',
              color: 'rgba(0,0,0,.35)',
              cursor: 'default',
            }}
            inputPlaceholder={t('modules.discounts.input_search')}
            inputSize={'large'}
            inputMaxLength={250}
            inputTimeout={700}
          />
        </div>

        <Tabs
          activeKey={tabActiveKey}
          onTabClick={(key: PromocodeType) => setTabActiveKey(key)}
          style={{ width: '100%', padding: '24px 16px 16px 16px' }}
        >
          <TabPane
            key="PERSONAL_COUPON"
            tab={<div className="discounts__tab-title">{t('modules.discounts.tab_pane.0')}</div>}
          >
            {isCouponsListLoading ? (
              <Loader />
            ) : (
              <CouponsTable couponsList={couponsList} pagination={pagination} onPageChange={onPageChange} />
            )}
          </TabPane>

          <TabPane
            disabled
            key="PROMOCODE"
            tab={<div className="discounts__tab-title">{t('modules.discounts.tab_pane.1')}</div>}
          ></TabPane>
        </Tabs>

        {isDiscountsFormModalOpened && (
          <DiscountsFormModal
            discountsRepository={props.discountsRepository}
            isDiscountsFormModalOpened={isDiscountsFormModalOpened}
            setIsDiscountsFormModalOpened={setIsDiscountsFormModalOpened}
            couponData={couponData}
            getCouponsList={getCouponsList}
          />
        )}
      </div>

      {loadingMessage}
    </section>
  );
}

export default Discounts;
