import React, { useState, useEffect } from 'react';
import styles from './AccreditationList.module.scss';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { PaginatedResult } from 'utils/typeHelpers';
import { fetchData, useDidMount, deserialize, sendData } from 'utils/apiHelpers';
import AccreditationCard from './components/AccreditationCard';
import { List, message, Affix, Checkbox } from 'antd';
import AccreditationFilter from './components/AccreditationFilter';
import AccreditationCategory from './components/AccreditationCategory';
import AccreditationTournamentsFilter from './components/AccreditationTournamentsFilter';
import AccreditationDropdownFilter from './components/AccreditationDropdownFilter';
import DropdownList from '../../../../common/DropdownList/DropdownList';
import { debounce } from 'lodash';
import { Pagination } from 'utils/commonTypes';
import { Pagination as AntPagination } from 'antd';
import {
  Accreditation,
  AccreditationFilterInput,
  AccreditationRejectionReason,
  AccreditationRejectionInput,
  AccreditationRequestStatus,
  Tournament,
  ACC_AccessCategory,
} from '../model/accreditationModel';
import {
  GET_ACCREDITATIONS_LIST,
  GET_REJECTION_REASONS,
  GET_TOURNAMENTS_LIST,
  GET_PDF_BY_IDS,
  GET_ACCSESS_CATEGORIES,
} from '../model/accreditationQueries';
import {
  accreditationDeserializer,
  rejectionReasonsDeserializer,
  tournamentsDeserializer,
  accessCategoryDeserializer,
} from '../model/accreditationDeserializers';
import { ACCEPT_ACCREDITATION, REJECT_ACCREDITATION } from '../model/accreditationMutations';
import AccreditationRejectionModal from './components/AccreditationRejectionModal';
import { allStatuses } from '../../../../constants';
import { useTranslation } from 'react-i18next';

const PAGINATION_STORAGE_KEY = 'pagination';
const FILTER_STORAGE_KEY = 'filter';

function AccreditationsList(props: { client: ApolloClient<any> }) {
  const { t } = useTranslation();

  enum SelectAllCheckboxState {
    ALL,
    NONE,
    SOME,
  }

  const [checkboxStateAccreditation, setCheckboxStateAccreditation] = useState<SelectAllCheckboxState>(
    SelectAllCheckboxState.NONE
  );

  const [loading, setLoading] = useState(false);
  const [accreditations, setAccreditations] = useState<PaginatedResult<Accreditation>>();

  const defaultPagination = { limit: 10, page: 0 };
  const paginationFromStorage = sessionStorage.getItem(PAGINATION_STORAGE_KEY);
  const initialPagination = paginationFromStorage ? JSON.parse(paginationFromStorage) : defaultPagination;
  const [pagination, setPagination] = useState(initialPagination);

  const filterFromStorage = sessionStorage.getItem(FILTER_STORAGE_KEY);
  const initialFilter = filterFromStorage ? JSON.parse(filterFromStorage) : undefined;
  const [filter, setFilter] = useState<AccreditationFilterInput | undefined>(initialFilter);

  const [accreditationReasons, setAccreditationReasons] = useState<AccreditationRejectionReason[]>();
  const [ids, setIds] = useState<string[]>();
  const [selectedAccreditations, setSelectedAccreditations] = useState<Accreditation[]>([]);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [category, setCategory] = useState<ACC_AccessCategory[]>([]);
  const [visibleFilters, setVisibleFilters] = useState<boolean>(window.screen.width >= 1200);

  function addWholePageToSelected() {
    if (accreditations) {
      let cloneSelectedAccreditations = [...selectedAccreditations];
      for (let accreditationItem of accreditations.list) {
        if (!selectedAccreditations.find((item) => item.id === accreditationItem.id)) {
          cloneSelectedAccreditations.unshift(accreditationItem);
        }
      }
      setSelectedAccreditations(cloneSelectedAccreditations);
    }
  }

  function removeWholePageFromSelected() {
    if (accreditations) {
      let cloneSelectedAccreditations = [...selectedAccreditations];
      for (let accreditationItem of accreditations.list) {
        cloneSelectedAccreditations = cloneSelectedAccreditations.filter((item) => item.id !== accreditationItem.id);
      }
      setSelectedAccreditations(cloneSelectedAccreditations);
    }
  }

  function onCheckboxClick() {
    switch (checkboxStateAccreditation) {
      case SelectAllCheckboxState.ALL: {
        removeWholePageFromSelected();
        setCheckboxStateAccreditation(SelectAllCheckboxState.NONE);
        break;
      }
      case SelectAllCheckboxState.NONE:
      case SelectAllCheckboxState.SOME: {
        addWholePageToSelected();
        setCheckboxStateAccreditation(SelectAllCheckboxState.ALL);
        break;
      }
    }
  }

  useEffect(() => {
    if (accreditations) {
      if (accreditations?.list.length === 0) {
        setCheckboxStateAccreditation(SelectAllCheckboxState.NONE);
      } else {
        let counter = 0;
        for (let accreditationItem of accreditations.list) {
          if (selectedAccreditations.find((item) => item.id === accreditationItem.id)) {
            counter++;
          }
        }
        switch (counter) {
          case accreditations.list.length: {
            setCheckboxStateAccreditation(SelectAllCheckboxState.ALL);
            break;
          }
          case 0: {
            setCheckboxStateAccreditation(SelectAllCheckboxState.NONE);
            break;
          }
          default: {
            setCheckboxStateAccreditation(SelectAllCheckboxState.SOME);
          }
        }
      }
    }
  }, [selectedAccreditations, accreditations]);

  useDidMount(() => {
    refreshList(pagination, filter);
    getTournaments();
    getCategory();
    fetchData(
      loading,
      setLoading,
      props.client.query({ query: GET_REJECTION_REASONS }),
      setAccreditationReasons,
      (res) => {
        return deserialize(res, (d) =>
          d
            .required('data.accreditation.rejectionReason.getList')
            .asArrayOfObjects((o) => rejectionReasonsDeserializer(o))
        );
      }
    );
  });

  function refreshList(pagination?: Pagination, filter?: AccreditationFilterInput) {
    fetchData(
      loading,
      setLoading,
      props.client.query({
        query: GET_ACCREDITATIONS_LIST,
        variables: { pagination, filter },
        fetchPolicy: 'no-cache',
      }),
      setAccreditations,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.accreditation.accreditation.getList').asObject((o) => ({
            total: o.required('total').asNumber,
            list: o.required('list').asArrayOfObjects((o) => accreditationDeserializer(o)),
          }))
        )
    );
  }

  function getTournaments() {
    fetchData(
      false,
      (state) => {},
      props.client.query({
        query: GET_TOURNAMENTS_LIST,
      }),
      setTournaments,
      (res) =>
        deserialize(res, (d) =>
          d.required('data.tournament.getList.list').asArrayOfObjects((o) => tournamentsDeserializer(o))
        )
    );
  }

  function getCategory() {
    fetchData(
      false,
      (state) => {},
      props.client.query({
        query: GET_ACCSESS_CATEGORIES,
      }),
      setCategory,
      (res) =>
        deserialize(res, (d) =>
          d
            .required('data.accreditation.accessCategory.getList.list')
            .asArrayOfObjects((c) => accessCategoryDeserializer(c))
        )
    );
  }

  function createNewPagination(page?: number): Pagination {
    return {
      ...pagination,
      page: page ? page - 1 : 0,
    };
  }

  function onPageChange(page?: number) {
    const newPagination = createNewPagination(page);
    refreshList(newPagination, filter);
    setPagination(newPagination);
    sessionStorage.setItem(PAGINATION_STORAGE_KEY, JSON.stringify(newPagination));
  }

  function onFilterChange(newFilterFields: AccreditationFilterInput) {
    const newFilter: AccreditationFilterInput = { ...filter, ...newFilterFields };
    let newPagination = defaultPagination;

    if (newFilterFields.quickSearch) {
      refreshList(undefined, newFilter);
    } else {
      newPagination = createNewPagination();
      refreshList(newPagination, newFilter);
    }

    setFilter(newFilter);
    setPagination(newPagination);
    sessionStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(newFilter));
  }

  function mutationSelectedAccreditations(accreditation: Accreditation): void {
    if (selectedAccreditations.some((item) => item.id === accreditation.id)) {
      setSelectedAccreditations([...selectedAccreditations.filter((item) => item.id !== accreditation.id)]);
    } else {
      setSelectedAccreditations([...selectedAccreditations, accreditation]);
    }
  }

  async function printAccreditations(accredIds: string[]) {
    message.loading(t('modules.accreditation_list.message_loading'), 0);
    const result = await props.client.query({
      query: GET_PDF_BY_IDS,
      variables: { ids: accredIds },
      fetchPolicy: 'no-cache',
    });

    result.data.accreditation.accreditation.getPdfByIds.forEach((item: any) => {
      window.open(`${item.publicLink}`);
      message.destroy();
    });
  }

  async function hadnleAcceptAccreditation(id: string) {
    sendData(loading, setLoading, props.client.mutate({ mutation: ACCEPT_ACCREDITATION, variables: { id } }), () =>
      refreshList(pagination, filter)
    );
  }

  function hadnleSelectTournament(value: string) {
    if (value === 'ALL') {
      onFilterChange({ tournamentIds: undefined });
    } else {
      onFilterChange({ tournamentIds: [value] });
    }
  }

  function handleSelectCategory(value: string) {
    if (value === 'ALL') {
      onFilterChange({ accessCategoryIds: undefined });
    } else {
      onFilterChange({ accessCategoryIds: [value] });
    }
  }

  async function hadnleRejectAccreditation(data: AccreditationRejectionInput) {
    const responses = ids?.forEach((id) => {
      sendData(
        loading,
        setLoading,
        props.client.mutate({ mutation: REJECT_ACCREDITATION, variables: { id, data } }),
        () => {
          setIds(undefined);
          refreshList(pagination, filter);
        }
      );
    });
    if (responses) return Promise.all(responses);
  }

  return (
    <>
      <h1 className="accreditation-title">{t('modules.accreditation_list.h1')}</h1>
      <div className="route-content route-content__accreditation">
        {visibleFilters ? (
          <div className={styles.filters__container}>
            <div className={styles.filter__container}>
              <div className={styles.filter__container_left}>
                <AccreditationTournamentsFilter
                  defaultValue={filter?.tournamentIds}
                  hadnleSelectTournament={hadnleSelectTournament}
                  list={[
                    {
                      value: 'ALL',
                      title: t('modules.accreditation_list.accreditation_tournaments_filter'),
                    },
                    ...tournaments.map((tournament) => ({
                      value: tournament.id,
                      title: tournament.title,
                    })),
                  ]}
                />

                <AccreditationDropdownFilter
                  defaultValue={filter?.accessCategoryIds}
                  handleSelectCategory={handleSelectCategory}
                  list={[
                    {
                      value: 'ALL',
                      title: t('modules.accreditation_list.accreditation_dropdown_filter'),
                    },
                    ...category.map((category) => ({
                      value: category.id,
                      title: category.title.toString(),
                    })),
                  ]}
                />
              </div>
              <AccreditationFilter onChange={debounce(onFilterChange, 500)} defaultValue={filter?.quickSearch} />
            </div>

            <div className={styles.menu__container}>
              <label className={styles.label}>
                <Checkbox
                  indeterminate={checkboxStateAccreditation === SelectAllCheckboxState.SOME}
                  onClick={onCheckboxClick}
                  checked={checkboxStateAccreditation !== SelectAllCheckboxState.NONE}
                  className={styles.checkbox}
                >
                  {t('modules.accreditation_list.checkbox')}
                </Checkbox>
              </label>
              <AccreditationCategory
                onFilterChange={onFilterChange}
                initialCategories={filter?.requestStatuses || allStatuses}
              />
            </div>
          </div>
        ) : null}

        {window.screen.width <= 1200 ? (
          <>
            <input id={styles.filter__input} className={styles.filter__input} type="checkbox" />
            <label
              className={styles.filter__label}
              htmlFor={styles.filter__input}
              onClick={() => {
                visibleFilters ? setVisibleFilters(false) : setVisibleFilters(true);
              }}
            >
              {visibleFilters
                ? t('modules.accreditation_list.visible_filters.0')
                : t('modules.accreditation_list.visible_filters.1')}
            </label>
          </>
        ) : null}
        {accreditationReasons?.length && (
          <AccreditationRejectionModal
            visible={!!ids}
            accreditationReasons={accreditationReasons}
            handleRejection={hadnleRejectAccreditation}
            closeModal={() => setIds(undefined)}
            loading={loading}
          />
        )}
        <List
          className={styles.list}
          itemLayout="vertical"
          loading={loading}
          dataSource={accreditations?.list
            .sort(sortAccreditationsByDate)
            .filter((accreditation) => accreditation.accreditedUser.user)}
          renderItem={(accreditation) => (
            <AccreditationCard
              accreditation={accreditation}
              selectedAccreditations={selectedAccreditations}
              acceptRequest={() => hadnleAcceptAccreditation(accreditation.id)}
              showModal={() => setIds([accreditation.id])}
              mutationSelectedAccreditations={mutationSelectedAccreditations}
              printAccreditations={() => printAccreditations([accreditation.id])}
            />
          )}
        />
        <Affix offsetBottom={0} className={styles.affixFooter}>
          <div className={styles.paginationFooter}>
            <DropdownList
              title={t('modules.accreditation_list.dropdown_list.title')}
              itemStyles={styles.dropdown__item}
              disabled={selectedAccreditations.length > 0 ? false : true}
              list={[
                {
                  id: 1,
                  buttonTitle: t('modules.accreditation_list.dropdown_list.list.0'),
                  clickAction: () =>
                    selectedAccreditations
                      .filter((accreditation) => accreditation.requestStatus === AccreditationRequestStatus.PENDING)
                      .map((accreditation) => hadnleAcceptAccreditation(accreditation.id)),
                },
                {
                  id: 2,
                  buttonTitle: t('modules.accreditation_list.dropdown_list.list.1'),
                  clickAction: () => {
                    selectedAccreditations
                      .filter((accreditation) => accreditation.requestStatus === AccreditationRequestStatus.PENDING)
                      .map((accreditation) => hadnleAcceptAccreditation(accreditation.id));
                    printAccreditations(
                      selectedAccreditations
                        .filter((accreditation) => accreditation.requestStatus === AccreditationRequestStatus.ACCEPTED)
                        .map((accreditation) => accreditation.id)
                    );
                  },
                },
                {
                  id: 3,
                  buttonTitle: t('modules.accreditation_list.dropdown_list.list.2'),
                  clickAction: () =>
                    printAccreditations(
                      selectedAccreditations
                        .filter((accreditation) => accreditation.requestStatus === AccreditationRequestStatus.ACCEPTED)
                        .map((accreditation) => accreditation.id)
                    ),
                },
                {
                  id: 4,
                  buttonTitle: t('modules.accreditation_list.dropdown_list.list.3'),
                  clickAction: () =>
                    setIds(
                      selectedAccreditations
                        .filter((accreditation) => accreditation.requestStatus === AccreditationRequestStatus.PENDING)
                        .map((accred) => accred.id)
                    ),
                },
              ]}
            />

            <span className={styles.paginationFooter__text}>
              {t('modules.accreditation_list.span')}: {selectedAccreditations.length}
            </span>

            <AntPagination
              className={styles.pagination}
              onChange={onPageChange}
              defaultCurrent={1}
              current={pagination.page + 1}
              defaultPageSize={pagination.limit}
              hideOnSinglePage={true}
              total={accreditations?.total}
              showSizeChanger={false}
            />
          </div>
        </Affix>
      </div>
    </>
  );
}

function sortAccreditationsByDate(a: Accreditation, b: Accreditation) {
  return b.createdAt.getTime() - a.createdAt.getTime();
}

export default withApollo(AccreditationsList);
