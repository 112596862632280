import 'moment/locale/ru.js';
import { ru } from 'date-fns/locale';
import { convertToTimeZone } from 'date-fns-timezone';
import { format } from 'date-fns';
import { t } from 'i18next';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

const cyrillicToTranslit = new CyrillicToTranslit();

export function parametrizeEnv(varName) {
  const varFromWindow = (window[varName] || '').trim();
  const isVarFromWindowEmpty = varFromWindow.includes('${') || varFromWindow === '';
  return isVarFromWindowEmpty ? process.env[varName] : varFromWindow;
}

export const getMoscowTime = (str, timeFormat) => {
  const date = new Date(str);
  const output = convertToTimeZone(date, {
    timeZone: 'Europe/Moscow',
  });
  return format(output, timeFormat, {
    locale: ru,
  });
};
export const getLocalData = (name, defaultValue) => {
  try {
    const data = JSON.parse(window.localStorage.getItem(name));
    return data ? data : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

// takes as input a number and an array of declensions of the word, returns the word in the desired declension depending on the number
export const declineWordDependingOnNumber = (number, words) => {
  return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]];
};

export const transliterate = (string) => {
  const strTrans = cyrillicToTranslit.transform(string);
  const res = strTrans.charAt(0).toUpperCase() + strTrans.slice(1);

  if (strTrans.length === 0) {
    return strTrans;
  } else {
    return res;
  }
};
export const indexOfToString = (arr, element) => {
  return arr.indexOf(element).toString();
};

export const statusToString = (requestStatus) => {
  switch (requestStatus) {
    case 'REJECTED':
      return t('modules.helpers.0');

    case 'PENDING':
      return t('modules.helpers.1');

    case 'ACCEPTED':
      return t('modules.helpers.2');

    default:
      return '';
  }
};

export const requestTypeToString = (requestType) => {
  switch (requestType) {
    case 'FOR_MATCH':
      return t('modules.helpers.3');

    case 'FOR_SEASON':
      return t('modules.helpers.4');

    case 'FOR_TOURNAMENT':
      return t('modules.helpers.5');
    default:
      return;
  }
};

export function requestTypeToSelectorString(type) {
  switch (type) {
    case 'FOR_MATCH':
      return t('modules.helpers.6');
    case 'FOR_SEASON':
      return t('modules.helpers.7');
    case 'FOR_TOURNAMENT':
      return t('modules.helpers.8');
    default:
      return;
  }
}

export function date(time) {
  if (time) {
    return new Date(time).toLocaleString();
  }
  return;
}

export function contactType(contacts, value) {
  return contacts?.find((contact) => contact.type === value)?.value;
}

export function extensionToFileType(extension) {
  const dict = {
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.png': 'image/png',
    '.svg': 'image/svg+xml',
    '.pdf': 'application/pdf',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };
  return dict[extension];
}

export const requestSearchUsersToString = (requestType) => {
  switch (requestType) {
    case 'SEARCH_INFO':
      return t('modules.helpers.9');

    case 'SEARCH_TELEFON':
      return t('modules.helpers.10');

    default:
      return;
  }
};

export const reformattingDate = (date, futureDay = false) => {
  if (futureDay) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
  } else {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  return date.toISOString();
};

export const trimStringProperties = (obj) => {
  const result = {};
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      result[key] = obj[key].trim();
    }
  }
  return result;
};
