import React, { useEffect, useState } from 'react';
import { isEmpty, pathOr, uniq } from 'ramda';
import { CloseCircleOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal, Pagination, Table } from 'antd';
import {
  CANCEL_RESERVATION,
  GET_MATCH_INFO,
  GET_SEASON_TICKETS_INFO,
  GET_SEASON_TICKETS_PDF,
  GET_TICKETS_PDF,
  GET_WEBTICKET_LINK,
  PARTIAL_REFUND_ORDER,
  SEND_ORDER_SMS,
  SEND_ORDER_TO_EMAIL,
  SEND_SEASON_TICKET_TO_EMAIL,
  SEND_TICKET_TO_EMAIL,
} from '../../graphql';
import InputComponent from '../InputComponent';
import ActionsSideMenu from './ActionsSideMenu';
import { useDidMount } from 'utils/apiHelpers';
import i18next, { t } from 'i18next';
import { LocalizedString } from 'utils/LocalizedString';
import checkTypeName, { RemoteTypeNames } from '../../utils/checkTypeName';

const TicketsTable = (props) => {
  const [stateProps, setState] = useState({
    page: 1,
    isLoaded: false,
    inputValue: '',
    total: 0,
    isActionsSideMenuVisible: false,
    isNewUserModalVisible: false,
    selectedRowKeys: [],
    selectedRows: [],
    isSingleUser: false,
  });

  // const [ tableWidth, setTableWidth ] = useState<number>(); // For the future
  const [tableWidth, setTableWidth] = useState();
  const size = useWindowSize();

  useDidMount(() => {
    setTableWidth(document.querySelector('.sales__tickets').clientWidth);
  });

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  useEffect(() => {
    setTableWidth(document.querySelector('.sales__tickets').clientWidth);
  }, [size]);

  const setLoading = () => {
    setState({
      ...stateProps,
      isLoaded: false,
      isActionsSideMenuVisible: false,
      selectedRowKeys: [],
      selectedRows: [],
      isSingleUser: false,
    });
  };

  const onSideMenuClose = () => {
    setState({
      ...stateProps,
      isActionsSideMenuVisible: false,
    });
  };

  const sendOrderSMS = async (phone, orderId) => {
    try {
      await props.client.query({
        query: SEND_ORDER_SMS,
        variables: {
          orderId,
          phone,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.tickets_table.message_success.sms_sent'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.sms_send_err'));
    }
  };

  const printTicketsPdf = async (ticketIds) => {
    try {
      message.loading(t('modules.tickets_table.message_loading'));
      const res = await props.client.query({
        query: GET_TICKETS_PDF,
        variables: {
          ids: ticketIds,
        },
      });
      const url = pathOr(false, ['data', 'ticket', 'getPdfByIds', 'publicLink'], res);
      window.open(url, '_blank');
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.ticket_load_err'));
    }
  };

  const printSeasonTicketsPdf = async (ticketIds) => {
    try {
      message.loading(t('modules.tickets_table.message_loading'));
      const res = await props.client.query({
        query: GET_SEASON_TICKETS_PDF,
        variables: {
          ids: ticketIds,
        },
      });
      const url = pathOr(false, ['data', 'seasonTicket', 'getPdfByIds', 'publicLink'], res);
      window.open(url, '_blank');
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.ticket_load_err'));
    }
  };

  const sendTicketsToEmail = async (ticketId) => {
    try {
      await props.client.query({
        query: SEND_TICKET_TO_EMAIL,
        variables: {
          ticketId,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.tickets_table.message_success.email_sent'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.send_email_err'));
    }
  };

  const sendOrderEmail = async (email, orderId) => {
    try {
      await props.client.query({
        query: SEND_ORDER_TO_EMAIL,
        variables: {
          orderId,
          overrideEmail: email,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.tickets_table.message_success.email_sent'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.send_email_err'));
    }
  };

  const sendSeasonTicketsToEmail = async (ticketId) => {
    try {
      await props.client.query({
        query: SEND_SEASON_TICKET_TO_EMAIL,
        variables: {
          seasonTicketId: ticketId,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.tickets_table.message_success.email_sent'));
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.send_email_err'));
    }
  };

  const cancelReservation = async (orderId, orderItemIds) => {
    try {
      await props.client.query({
        query: CANCEL_RESERVATION,
        variables: {
          orderId,
          orderItemIds,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.tickets_table.message_success.reservation_removed'));
      setState({
        ...stateProps,
        selectedRows: [],
        isSingleUser: false,
        selectedRowKeys: [],
        isAcceptRefundModalVisible: false,
        isLoaded: false,
        isRefundProcessing: false,
        dropDownMode: '',
      });
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.booking_err'));
    }
  };

  const initRefund = async (orderId, orderItemIds) => {
    try {
      await props.client.query({
        query: PARTIAL_REFUND_ORDER,
        variables: {
          orderId,
          orderItemIds,
        },
        fetchPolicy: 'no-cache',
      });
      message.success(t('modules.tickets_table.message_success.ticket_refunded'));
      setState({
        ...stateProps,
        selectedRows: [],
        isSingleUser: false,
        selectedRowKeys: [],
        isAcceptRefundModalVisible: false,
        isLoaded: false,
        isRefundProcessing: false,
        dropDownMode: '',
      });
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.ticket_return_err'));
    }
  };

  const setTextSafari = async (orderItemIds) => {
    const linkGeneration = async () => {
      try {
        const result = await props.client.query({
          query: GET_WEBTICKET_LINK,
          variables: {
            itemIds: orderItemIds,
          },
          fetchPolicy: 'no-cache',
        });
        const ticketsLink = result.data['order']['getSeveralShortWebMobileLink'];
        return ticketsLink;
      } catch (e) {
        console.log(e);
        message.error(e);
      }
    };
    const clipboardItem = new window.ClipboardItem({
      'text/plain': linkGeneration()
        .then((result) => {
          if (!result) {
            return new Promise(async (resolve) => {
              resolve(new Blob[``]());
            });
          }

          const copyText = result;
          message.success(t('modules.tickets_table.message_success.link_copied'));
          return new Promise(async (resolve) => {
            resolve(new Blob([copyText]));
          });
        })
        .catch(
          (e) => console.log(e),
          () => message.error(t('modules.tickets_table.message_error.tickets_link_err'))
        ),
    });
    await navigator.clipboard.write([clipboardItem]);
  };

  const copyOrderItemsWebTicketLink = async (orderItemIds) => {
    try {
      const currentBrowser = window.navigator.userAgent;
      const indexSafari = currentBrowser.indexOf('Safari');
      const indexChrome = currentBrowser.indexOf('Chrome');

      if (indexSafari > 0 && indexChrome < 0) {
        setTextSafari(orderItemIds);
      } else {
        const result = await props.client.query({
          query: GET_WEBTICKET_LINK,
          variables: {
            itemIds: orderItemIds,
          },
          fetchPolicy: 'no-cache',
        });
        const ticketsLink = result.data['order']['getSeveralShortWebMobileLink'];

        await navigator.clipboard.writeText(ticketsLink).then(
          () => message.success(t('modules.tickets_table.message_success.link_copied')),
          () => message.error(t('modules.tickets_table.message_error.tickets_link_err'))
        );
      }
    } catch (e) {
      console.error(e);
      message.error(t('modules.tickets_table.message_error.tickets_link_err'));
    }
  };

  const columns = [
    {
      title: t('modules.tickets_table.columns.number'),
      dataIndex: 'visibleId',
      key: 'visibleId',
    },
    {
      title: t('modules.tickets_table.columns.full_name'),
      dataIndex: 'userName',
      key: 'username',
    },
    {
      title: t('modules.tickets_table.columns.sector'),
      dataIndex: 'sector',
      key: 'sector',
    },
    {
      title: t('modules.tickets_table.columns.row'),
      dataIndex: 'row',
      key: 'row',
    },
    {
      title: t('modules.tickets_table.columns.seat'),
      dataIndex: 'place',
      key: 'place',
    },
    {
      title: t('modules.tickets_table.columns.price'),
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: t('modules.tickets_table.columns.discounted_price'),
      dataIndex: 'priceWithDiscount',
      key: 'priceWithDiscount',
    },
    {
      title: t('modules.tickets_table.columns.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('modules.tickets_table.columns.purchase_date'),
      dataIndex: 'cratedTime',
      key: 'createdTime',
    },
    {
      title: t('modules.tickets_table.columns.comment'),
      dataIndex: 'commentary',
      key: 'commentary',
    },
  ];

  const rowSelection = {
    onChange: (keys, selectedRows) => {
      if (!stateProps.isActionsSideMenuVisible) {
        const dropDownMode = [
          t('modules.tickets_table.drop_down_mode.bought'),
          t('modules.tickets_table.drop_down_mode.booked'),
        ].map((item) => {
          const newArr = selectedRows.filter((row) => {
            return row.status === item;
          });
          if (newArr.length === selectedRows.length) {
            return item;
          }
          return '';
        });

        const loginArr = uniq(
          selectedRows.map((item) => {
            return item.login;
          })
        );

        setState({
          ...stateProps,
          isSingleUser: loginArr.length === 1 ? true : false,
          selectedRowKeys: keys.map((key) => {
            return key + (stateProps.page * 10 - 10);
          }),
          selectedRows,
          dropDownMode: isEmpty(selectedRows) ? '' : dropDownMode.filter(Boolean)[0],
        });
      }
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    const init = async () => {
      const additionalVariables = isEmpty(props.filter)
        ? {}
        : {
            status: props.filter.status,
            passedToEvent: props.filter.passedToEvent,
            period: {
              start: props.filter.period.start,
              end: props.filter.period.end,
            },
            price: {
              start: props.filter.priceRange.start,
              end: props.filter.priceRange.end,
            },
          };
      const additionalVariablesSeasonTicket = isEmpty(props.filter)
        ? {}
        : {
            period: {
              start: props.filter.period.start,
              end: props.filter.period.end,
            },
            price: {
              start: props.filter.priceRange.start,
              end: props.filter.priceRange.end,
            },
            statuses: props.filter.status,
            passedToEvents: {
              passedToEvent: props.filter.passedToEvent,
            },
          };
      const data = await props.client.query({
        query: checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR)
          ? GET_SEASON_TICKETS_INFO
          : GET_MATCH_INFO,
        fetchPolicy: 'no-cache',
        variables: {
          filter: checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR)
            ? {
                seasonTicketDescriptorIds: props.event.id,
                quickSearch: stateProps.inputValue ? stateProps.inputValue : null,
                ...additionalVariablesSeasonTicket,
              }
            : {
                eventIds: props.event.id,
                quickSearch: stateProps.inputValue ? stateProps.inputValue : null,
                ...additionalVariables,
              },
          paging: {
            limit: 20,
            page: stateProps.page - 1,
          },
        },
      });

      const list = pathOr(
        [],
        [
          'data',
          checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR) ? 'seasonTicket' : 'ticket',
          'getList',
          'list',
        ],
        data
      );
      const total = pathOr(
        [],
        [
          'data',
          checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR) ? 'seasonTicket' : 'ticket',
          'getList',
          'total',
        ],
        data
      );
      const filteredList = list.map((item) => {
        const person = pathOr({}, ['user', 'person'], item);
        const date = new Date(
          pathOr(
            0,
            [checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR) ? 'purchaseDate' : 'createdTime'],
            item
          )
        );
        const contacts = pathOr([], ['user', 'person', 'contacts'], item);
        const email = contacts.find((item) => {
          return item.type === 'EMAIL';
        });
        const day = date.getDate();
        return {
          ticketId: item['id'],
          id: pathOr('', ['order', 'id'], item),
          orderItemId: pathOr('', ['orderItem', 'id'], item),
          email: pathOr('', ['value'], email),
          login: pathOr('', ['user', 'login'], item),
          visibleId: item.visibleId,
          userName:
            LocalizedString.fromObject(pathOr('', ['surname'], person)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['name'], person)).toString(i18next.language) +
            ' ' +
            LocalizedString.fromObject(pathOr('', ['patronymic'], person)).toString(i18next.language),
          sector: pathOr('', ['place', 'row', 'sector', 'title'], item),
          row: pathOr('', ['place', 'row', 'number'], item),
          place: pathOr('', ['place', 'number'], item),
          price: Math.floor(parseInt(pathOr('', ['price'], item))),
          priceWithDiscount: pathOr(null, ['orderItem', 'priceWithDiscount'], item)
            ? Math.floor(parseInt(pathOr('', ['orderItem', 'priceWithDiscount'], item)))
            : Math.floor(parseInt(pathOr('', ['price'], item))),
          status: (() => {
            switch (pathOr('', ['status'], item)) {
              case 'SOLD':
                return t('modules.tickets_table.case.bought');
              case 'RESERVED':
                return t('modules.tickets_table.case.booked');
              case 'PAYMENT_PROCESSING':
                return t('modules.tickets_table.case.in_progress');
              case 'CANCELED':
                return t('modules.tickets_table.case.canceled');
              case 'REFUND':
                return t('modules.tickets_table.case.refunded');
              case 'PARTIAL_REFUND':
                return t('modules.tickets_table.case.partial_refund');
              default:
                return t('modules.tickets_table.case.unknown');
            }
          })(),
          commentary: pathOr('', ['order', 'additionalData', 'commentary'], item),
          cratedTime:
            (day < 10 ? '0' + day : day) +
            '.' +
            props.getMonth(date.getMonth(), true) +
            '.' +
            date.getFullYear() +
            ' ' +
            props.getTime(date),
        };
      });
      setState({
        ...stateProps,
        isLoaded: true,
        filteredList,
        total: total,
      });
    };
    if (!stateProps.isLoaded) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProps.isLoaded]);

  return (
    <>
      <InputComponent
        handleSearchChange={(inputValue) => {
          setState({
            ...stateProps,
            inputValue,
            isLoaded: false,
          });
        }}
        inputContainerStyle={{}}
        inputTimeout={700}
        suffixIconType="search"
        iconOnClick={() => {}}
        allowClear={true}
        iconStyle={{
          color: 'grey',
          cursor: 'default',
        }}
        inputPlaceholder={t('modules.tickets_table.input_component')}
        inputClassName="sales__tickets__list-table__input"
      />
      <div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="ticketId"
          dataSource={stateProps.filteredList}
          pagination={false}
          loading={!stateProps.isLoaded}
        />
      </div>
      {!isEmpty(stateProps.filteredList) && stateProps.isLoaded ? (
        <div className="sales__tickets__list-table__dropdown__wrapper-absolute" style={{ maxWidth: `${tableWidth}px` }}>
          <div className="sales__tickets__list-table__dropdown__wrapper-fixed">
            <Dropdown
              className="sales__tickets__list-table__dropdown__container"
              disabled={isEmpty(stateProps.selectedRows)}
              trigger={['click']}
              visible={stateProps.isDropdownVisible}
              overlay={
                <Menu>
                  {stateProps.dropDownMode === t('modules.tickets_table.menu.booked') && (
                    <Menu.Item
                      onClick={() => {
                        if (stateProps.isSingleUser) {
                          setState({
                            ...stateProps,
                            isDropdownVisible: false,
                            isActionsSideMenuVisible: true,
                          });
                        } else {
                          message.warning(t('modules.tickets_table.message_warning'));
                        }
                      }}
                    >
                      {t('modules.tickets_table.menu_item.buy_entire_order')}
                    </Menu.Item>
                  )}
                  {stateProps.dropDownMode === t('modules.tickets_table.menu.booked') && (
                    <Menu.Item
                      onClick={() => {
                        setState({
                          ...stateProps,
                          isDropdownVisible: false,
                          isAcceptRefundModalVisible: true,
                        });
                      }}
                    >
                      {t('modules.tickets_table.menu_item.cancel_reservation')}
                    </Menu.Item>
                  )}
                  {checkTypeName(props.event, RemoteTypeNames.MATCH) &&
                    stateProps.dropDownMode === t('modules.tickets_table.menu.bought') && (
                      <Menu.Item
                        onClick={() => {
                          setState({
                            ...stateProps,
                            isDropdownVisible: false,
                            isAcceptRefundModalVisible: true,
                          });
                        }}
                      >
                        {t('modules.tickets_table.menu_item.refund')}
                      </Menu.Item>
                    )}
                  <Menu.Item
                    onClick={() => {
                      const ticketIds = stateProps.selectedRows.map((item) => {
                        return item.ticketId;
                      });
                      if (checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR)) {
                        ticketIds.forEach((id) => {
                          sendSeasonTicketsToEmail(id);
                        });
                      } else {
                        ticketIds.forEach((id) => {
                          sendTicketsToEmail(id);
                        });
                      }
                      setState({
                        ...stateProps,
                        isDropdownVisible: false,
                      });
                    }}
                  >
                    {t('modules.tickets_table.menu_item.email_send')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      const arr = stateProps.selectedRows.map((item) => {
                        return [item.login, item.id];
                      });
                      uniq(arr).forEach((item) => {
                        sendOrderSMS(item[0], item[1]);
                      });
                      setState({
                        ...stateProps,
                        isDropdownVisible: false,
                      });
                    }}
                  >
                    {t('modules.tickets_table.menu_item.sms_send')}
                  </Menu.Item>
                  {stateProps.dropDownMode === t('modules.tickets_table.menu.bought') && (
                    <Menu.Item
                      onClick={() => {
                        const arr = stateProps.selectedRows.map((item) => item.orderItemId);
                        copyOrderItemsWebTicketLink(arr);
                        setState({
                          ...stateProps,
                          isDropdownVisible: false,
                        });
                      }}
                    >
                      {t('modules.tickets_table.menu_item.copy_link')}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={() => {
                      const ticketIds = stateProps.selectedRows.map((item) => {
                        return item.ticketId;
                      });
                      if (checkTypeName(props.event, RemoteTypeNames.SEASON_TICKET_DESCRIPTOR)) {
                        printSeasonTicketsPdf(ticketIds);
                      } else {
                        printTicketsPdf(ticketIds);
                      }
                      setState({
                        ...stateProps,
                        isDropdownVisible: false,
                      });
                    }}
                  >
                    {t('modules.tickets_table.menu_item.print')}
                  </Menu.Item>
                </Menu>
              }
              onClick={() => {
                setState({
                  ...stateProps,
                  isDropdownVisible: true,
                });
              }}
              onVisibleChange={(ev) => {
                setState({
                  ...stateProps,
                  isDropdownVisible: ev,
                });
              }}
            >
              <div>
                <div className="sales__tickets__filter-modal__dropdown__item">{t('modules.tickets_table.div.actions')}</div>
                <UpOutlined className="sales__tickets__filter-modal__dropdown__icon" />
              </div>
            </Dropdown>

            <Pagination
              pageSize={20}
              showSizeChanger={false}
              total={stateProps.total}
              current={stateProps.page}
              className="sales__tickets__list-table__pagination"
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  page: ev,
                  isLoaded: false,
                });
              }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {stateProps.isActionsSideMenuVisible ? (
        <ActionsSideMenu
          setLoading={() => {
            setLoading();
          }}
          authClient={props.authClient}
          selectedRows={stateProps.selectedRows}
          client={props.client}
          sendOrderSMS={sendOrderSMS}
          sendOrderEmail={sendOrderEmail}
          onClose={() => {
            onSideMenuClose();
          }}
        />
      ) : (
        ''
      )}
      <Modal
        visible={stateProps.isAcceptRefundModalVisible}
        centered
        closable={false}
        onCancel={() => {
          setState({
            ...stateProps,
            isAcceptRefundModalVisible: false,
          });
        }}
        width={415}
        footer={null}
      >
        <>
          <div className="sales__tickets__list-table__accept-modal__text-container">
            <CloseCircleOutlined className="sales__tickets__list-table__accept-modal__icon" />
            <div className="sales__tickets__list-table__accept-modal__text">
              {stateProps.dropDownMode === t('modules.tickets_table.div.booked')
                ? t('modules.tickets_table.div.remove_confirm')
                : stateProps.dropDownMode === t('modules.tickets_table.div.bought')
                ? t('modules.tickets_table.div.return_confirm')
                : ''}
            </div>
          </div>
          <div className="sales__tickets__list-table__accept-modal__button-container">
            <Button
              id="TicketsTableCancelButton"
              className="sales__tickets__list-table__accept-modal__decline-button"
              onClick={() => {
                setState({
                  ...stateProps,
                  isAcceptRefundModalVisible: false,
                });
              }}
            >
              {t('modules.tickets_table.button.cancel')}
            </Button>
            <Button
              id="TicketsTableReturnButton"
              className="sales__tickets__list-table__accept-modal__accept-button"
              onClick={async () => {
                setState({
                  ...stateProps,
                  isRefundProcessing: true,
                });
                if (stateProps.dropDownMode === t('modules.tickets_table.drop_down_mode.bought')) {
                  const idsArr = stateProps.selectedRows.map((item) => {
                    return item.id;
                  });

                  const uniqArr = uniq(idsArr);

                  for (const id of uniqArr) {
                    const itemsArr = stateProps.selectedRows
                    .filter((item) => {
                      return item.id === id;
                    })
                    .map((item) => {
                      return item.orderItemId;
                    });
                    await initRefund(id, itemsArr);
                  }
                }
                if (stateProps.dropDownMode === t('modules.tickets_table.drop_down_mode.booked')) {
                  const idsArr = stateProps.selectedRows.map((item) => {
                    return item.id;
                  });

                  const uniqArr = uniq(idsArr);

                  for (const id of uniqArr) {
                    const itemsArr = stateProps.selectedRows
                    .filter((item) => {
                      return item.id === id;
                    })
                    .map((item) => {
                      return item.orderItemId;
                    });
                    await cancelReservation(id, itemsArr);
                  }
                }
              }}
            >
              {stateProps.isRefundProcessing ? (
                <LoadingOutlined />
              ) : stateProps.dropDownMode === t('modules.tickets_table.drop_down_mode.booked') ? (
                t('modules.tickets_table.button.return')
              ) : stateProps.dropDownMode === t('modules.tickets_table.drop_down_mode.bought') ? (
                t('modules.tickets_table.button.return')
              ) : (
                ''
              )}
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default TicketsTable;
