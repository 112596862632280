import React, {useEffect, useState} from 'react';
import styles from './UserRegistration.module.scss';
import {TransliterationInput, TransliterationInputProps} from 'common/TransliterationInput';
import UploadFormItem from './UploadFormItem/UploadFormItem';
import avatarPlaceholder from '../../img/avatar_placeholder.svg';
import './UserRegistration.module.scss';
import ApolloClient from 'apollo-client';
import {Button, ConfigProvider, DatePicker, Drawer, Form, Input, message, Select} from 'antd';
import {Icon} from '@ant-design/compatible';
import {LoadingOutlined} from '@ant-design/icons';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {v4 as uuid4} from 'uuid';

import {useDidMount} from 'utils/apiHelpers';
import {useLoadingMessage} from 'common/useLoadingMessage/useLoadingMessage';
import generateError from '../../utils/generateError';
import {MESSAGE_TIME_DURATION} from 'constants.js';
import {ERROR_MESSAGE_CONTENT, FORMAT_DATE} from '../../../constants';
import {UsersRepository, UsersRepositoryImpl} from '../../model/UsersRepository';
import {
  ContactInput,
  ContactType,
  FilteredRole,
  FormValues,
  InsertUser,
  PersonData,
  SelectFormItem
} from '../../model/usersModel';
import {useTranslation} from 'react-i18next';

function UserRegistration(props: {
  isModalOpened: boolean;
  setIsModalOpened: (value: boolean) => void;
  client: ApolloClient<any>;
  getUsersByPage: (currentSearchPage: number, searchString: string) => void;
}) {
  const { t } = useTranslation();
  const usersRepositoryImpl: UsersRepository = new UsersRepositoryImpl(props.client);
  const { Option } = Select;

  const [rolesList, setRolesList] = useState<FilteredRole[]>();
  const [avatar, setAvatar] = useState<string>(avatarPlaceholder);
  const [photoFileId, setPhotoFileId] = useState<string>();

  const [loadingMessage, setLoadingMessage] = useLoadingMessage(t('modules.user_registration.use_loading_message'));
  const [isRolesLoading, setIsRolesLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  async function getRolesList() {
    setIsRolesLoading(true);
    try {
      setRolesList(await usersRepositoryImpl.getRolesList());
    } catch (e) {
      console.log('getRolesList error', e);
      generateError(`${ERROR_MESSAGE_CONTENT.loadRolesError} ${e}`);
    } finally {
      setIsRolesLoading(false);
    }
  }

  useDidMount(() => {
    getRolesList();
  });

  useEffect(() => {
    setLoadingMessage(isRolesLoading || isSubmitLoading);
  }, [isRolesLoading, isSubmitLoading]);

  const rolesElement =
    rolesList &&
    rolesList.map((role: FilteredRole) => (
      <Option
        key={role.id}
        title={role.name}
        value={role.id}
        style={{
          backgroundColor: '#fff'
        }}
      >
        {role.name}
      </Option>
    ));

  const commonTransliterationProps: Partial<TransliterationInputProps> = {
    labelPosition: 'above',
    direction: 'row',
    columns: 2,
    justify: 'space-between',
    wrapperClasses: styles.localizedGroupWrapper
  };

  async function onFinish(values: FormValues) {
    setIsSubmitLoading(true);

    const rolesData: string[] = values.roles.map((item: SelectFormItem) => item.value);
    const contactsData: ContactInput[] = [
      {
        type: ContactType.PHONE,
        value: values.phone
      },
      {
        type: ContactType.EMAIL,
        value: values.email,
      },
    ];
    const personData: PersonData = {
      name: values.name.toObject(),
      patronymic: values.patronymic && values.patronymic.toObject(),
      surname: values.surname.toObject(),
      photoFileId: photoFileId,
      birthday: values.birthday && moment(values.birthday).format('YYYY-MM-DD'),
      contacts: contactsData,
      tin: values.tin,
    };

    const data: InsertUser = {
      login: values.phone,
      password: uuid4(),
      person: personData,
      roles: rolesData,
    };

    try {
      await usersRepositoryImpl.insertUser(data);
      message.success(t('modules.user_registration.message_success'), MESSAGE_TIME_DURATION);
      setIsSubmitLoading(false);
      props.getUsersByPage(0, '');
      props.setIsModalOpened(false);
    } catch (e: any) {
      console.log('insertUser error: ', e);
      if (e.message.includes('51cd1044')) {
        generateError(`${ERROR_MESSAGE_CONTENT.sameNumberError}`);
      } else {
        generateError(`${ERROR_MESSAGE_CONTENT.userRegistrationError} ${e}`);
      }
      setIsSubmitLoading(false);
    } finally {
    }
  }
  return (
    <Drawer
      placement="right"
      closable={false}
      visible={props.isModalOpened}
      className={styles.userRegistration}
      footer={null}
      width="80%"
      height="100%"
      bodyStyle={{ backgroundColor: 'var(--admin-theme_Grey_00)' }}
    >
      <Form name="userRegistration" onFinish={onFinish}>
        <div className={styles.modalTitleBlock}>
          <Icon type="left" className={styles.modalCloseIcon} onClick={() => props.setIsModalOpened(false)} />
          <h2 className={styles.modalTitle}>{t('modules.user_registration.h2.0')}</h2>
          <Button
            id="UserRegistrationRegisterButton"
            size="large"
            className={styles.modalSaveButton}
            htmlType="submit"
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? <LoadingOutlined /> : t('modules.user_registration.button')}
          </Button>
        </div>
        <div className={styles.modalBody}>
          <h2 className={styles.formTitle}>{t('modules.user_registration.h2.1')}</h2>
          <Form.Item
            name="surname"
            className={styles.localizedTextItem}
          >
            <TransliterationInput
              inputName={'surname'}
              labelTitle={t('modules.user_registration.localized_text_input.surname') || undefined}
              validation={{required: {value: true, message: t('modules.user_registration.form_item.surname')!}}}
              {...commonTransliterationProps}
            />
          </Form.Item>
          <Form.Item
            name="name"
            className={styles.localizedTextItem}
          >
            <TransliterationInput
              inputName={'name'}
              labelTitle={t('modules.user_registration.localized_text_input.name') || undefined}
              validation={{required: {value: true, message: t('modules.user_registration.form_item.name')!}}}
              {...commonTransliterationProps}
            />
          </Form.Item>
          <Form.Item name="patronymic" className={styles.localizedTextItem} rules={[{required: false}]}>
            <TransliterationInput
              inputName={'patronymic'}
              labelTitle={t('modules.user_registration.localized_text_input.patronymic') || undefined}
              {...commonTransliterationProps}
            />
          </Form.Item>

          {/*TODO: make three separate elements: day, month, year*/}
          <h3 className={styles.itemTitle}>{t('modules.user_registration.h3.0')}</h3>
          <ConfigProvider
            theme={{
              token: {
                colorInfo: window.parametrize('REACT_APP_LINK_COLOR'),
              },
            }}
          >
            <Form.Item name="birthday" className={styles.item}>
              <DatePicker id="birthday" size="large" format={FORMAT_DATE} />
            </Form.Item>
          </ConfigProvider>
          <span className={styles.prompt}>{t('modules.user_registration.span.0')}</span>

          <h3 className={styles.itemTitle}>{t('modules.user_registration.h3.1')}</h3>
          <UploadFormItem avatar={avatar} setAvatar={setAvatar} setPhotoFileId={setPhotoFileId} />

          <h2 className={styles.formTitle}>{t('modules.user_registration.h2.2')}</h2>
          <h3 className={styles.itemTitle}>{t('modules.user_registration.h3.2')}</h3>
          <Form.Item
            name="roles"
            className={styles.item}
            rules={[{required: true, message: t('modules.user_registration.form_item.role')!}]}
          >
            <Select
              mode="multiple"
              size="large"
              className={styles.select}
              showArrow
              labelInValue
              placeholder={t('modules.user_registration.select')}
            >
              {rolesElement}
            </Select>
          </Form.Item>

          <h3 className={styles.itemTitle}>{t('modules.user_registration.h3.3')}</h3>
          <Form.Item name="tin" className={styles.item}>
            <Input id="tin" type="number" size="large" className={styles.input} autoComplete="off" />
          </Form.Item>
          <span className={styles.prompt}>{t('modules.user_registration.span.1')}</span>

          <h2 className={styles.formTitle}>{t('modules.user_registration.h2.3')}</h2>
          <h3 className={styles.itemTitle}>E-mail</h3>
          <Form.Item
            name="email"
            className={styles.item}
            rules={[{required: true, message: t('modules.user_registration.form_item.email')!}]}
          >
            <Input
              id="email"
              type="email"
              size="large"
              className={styles.input}
              autoComplete="email"
              spellCheck={false}
            />
          </Form.Item>
          <span className={styles.prompt}>{t('modules.user_registration.span.2')}</span>

          <h3 className={styles.itemTitle}>{t('modules.user_registration.h3.4')}</h3>
          <Form.Item
            name="phone"
            className={styles.item}
            rules={[
              {
                required: true,
                message: t('modules.user_registration.form_item.phone')!,
                pattern: /\+?7\s?\(?\d{3}\)?\s?\d{3}-?\d{2}-?\d{2}/,
                validateTrigger: 'onsubmit'
              }
            ]}
            normalize={(e) => e.match(/\d/g).join('')}
          >
            <InputMask
              id="phone"
              mask="+7 (999) 999-99-99"
              placeholder={'+7'}
              className={styles.inputMask}
              autoComplete="tel"
            />
          </Form.Item>
          <span className={styles.prompt}>{t('modules.user_registration.span.3')}</span>
        </div>

        {loadingMessage}
      </Form>
    </Drawer>
  );
}

export default UserRegistration;
