import React, { useState } from 'react';
import styles from './AccreditationRejectionModal.module.scss';
import { Modal, Select, Input, Button } from 'antd';
import { AccreditationRejectionReason, AccreditationRejectionInput } from '../../model/accreditationModel';
import { useTranslation } from 'react-i18next';

export default function AccreditationRejectionModal(props: {
  accreditationReasons: AccreditationRejectionReason[];
  handleRejection: (data: AccreditationRejectionInput) => void;
  closeModal: () => void;
  visible: boolean;
  loading: boolean;
}) {
  const { t } = useTranslation();
  const [data, setData] = useState<AccreditationRejectionInput | undefined>(undefined);

  return (
    <Modal className={styles.modal} visible={props.visible} closable={false} destroyOnClose footer={null}>
      <h1>{t('modules.accreditation_rejection_modal.h1')}</h1>
      <label>{t('modules.accreditation_rejection_modal.label')}:</label>
      <Select
        className={styles.select}
        placeholder={t('modules.accreditation_rejection_modal.select')}
        onChange={(value) => setData({ ...data, rejectionReasonId: value!.toString() })}
      >
        {props.accreditationReasons.map((reason) => (
          <Select.Option key={reason.id} value={reason.id}>
            {reason.title.toString()}
          </Select.Option>
        ))}
      </Select>
      <Input.TextArea
        placeholder={t('modules.accreditation_rejection_modal.input_textarea')!}
        rows={3}
        onChange={(e) => {
          if (data) setData({ ...data, notes: e.target.value });
        }}
      />
      <div className={styles.footer}>
        <Button
          id="AccreditationRejectionModalCancelButton"
          onClick={() => {
            props.closeModal();
            setData(undefined);
          }}
          type="ghost"
        >
          {t('modules.accreditation_rejection_modal.button.0')}
        </Button>
        <Button
          id="AccreditationRejectionModalRejectApplicationButton"
          className={styles.okButton}
          disabled={!data?.rejectionReasonId}
          onClick={() => {
            if (data) props.handleRejection(data);
          }}
          loading={props.loading}
        >
          {t('modules.accreditation_rejection_modal.button.1')}
        </Button>
      </div>
    </Modal>
  );
}
