import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './AddUsersModal.module.scss';
import AddUsersItem from './AddUsersItem/AddUsersItem';

import useDebounce from '../../utils/useDebounce';
import { useLoadingMessage } from '../../utils/useLoadingMessage';
import { PhonesListInput, WayToAddUsersType, AddUsersInitialFormValues } from '../../model/discountsModel';
import { User } from 'common/users/types';
import { DiscountsRepository } from '../../model/DiscountsRepository';
import { ERROR_MESSAGE_CONTENT, ERROR_MESSAGE_STYLE } from '../../../constants';
import { ERROR_MESSAGE_TIME_DURATION, MESSAGE_TIME_DURATION } from 'constants.js';
import { useTranslation } from 'react-i18next';

function AddUsersModal(props: {
  isAddUsersModalOpened: boolean;
  setIsAddUsersModalOpened: (value: boolean) => void;
  couponId: string;
  discountsRepository: DiscountsRepository;
  getCouponsUsers: () => void;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const initialValues: AddUsersInitialFormValues = {
    addUsers: [],
    addUsersRadios: WayToAddUsersType.SEARCH_USER,
    couponsNumber: undefined,
  };
  const [wrongPhonesList, setWrongPhonesList] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<PhonesListInput>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useLoadingMessage(t('modules.add_users_modal.use_loading_message'));

  async function onFinish(values: AddUsersInitialFormValues) {
    setIsSubmitLoading(true);

    const userIds: string[] = values.addUsers.map((item: User) => item.userId);

    try {
      for (let i = 0; i < Number(values.couponsNumber); i++) {
        await props.discountsRepository.addCouponToUsers(props.couponId, userIds);
      }
      message.success(t('modules.add_users_modal.message_success'), MESSAGE_TIME_DURATION);
      props.getCouponsUsers();
      setIsSubmitLoading(false);
      props.setIsAddUsersModalOpened(false);
    } catch (e) {
      console.log('addCouponToUsers error', e);
      message.error({
        content: `${ERROR_MESSAGE_CONTENT.addUsersError} ${e}`,
        duration: ERROR_MESSAGE_TIME_DURATION,
        style: ERROR_MESSAGE_STYLE,
      });
      setIsSubmitLoading(false);
    } finally {
    }
  }

  // The function of adding users by the list of phone numbers after entering the list of numbers into the input with a delay of 2 seconds
  const debouncedSearchTerm = useDebounce<PhonesListInput>(inputValue, 2000);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoadingMessage(true);
      addUsersFromPhones(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  async function addUsersFromPhones(value: PhonesListInput) {
    if (value.phonesList) {
      const loginsArr = value.phonesList
        .match(/[0-9]/g)
        ?.join('')
        .match(/[0-9]{11}/g);
      const newUsers: User[] = [];
      const wrongPhones: string[] = [];

      loginsArr &&
        (await Promise.all(
          loginsArr.map(async (login: string) => {
            try {
              const newUser = await props.discountsRepository.getUserByLogin(login);
              newUser && newUsers.push(newUser);
            } catch (e) {
              // Erroneous numbers are collected in a separate array for drawing in a separate block
              console.error(e);
              console.error('Wrong phone: ', login);
              wrongPhones.push(login);
            } finally {
            }
          })
        ));

      await form.setFieldsValue({
        addUsers: [...newUsers, ...form.getFieldValue('addUsers')],
        phonesList: '',
      });
      await setWrongPhonesList([...wrongPhonesList, ...wrongPhones]);
    }
    setLoadingMessage(false);
  }

  return (
    <Modal
      visible={props.isAddUsersModalOpened}
      onCancel={() => props.setIsAddUsersModalOpened(false)}
      title={<h3 className={styles.modalTitle}>{t('modules.add_users_modal.modal')}</h3>}
      footer={false}
      width={914}
      closeIcon={<PlusOutlined className={styles.closeIcon} />}
    >
      <Form
        name="addUsersForm"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={(value: any) => {
          if (value.phonesList) {
            setInputValue(value);
          }
        }}
      >
        <Form.Item
          name="addUsers"
          htmlFor="addUsers"
          shouldUpdate
          rules={[{ required: true, message: t('modules.add_users_modal.form_item.0')! }]}
        >
          <AddUsersItem wrongPhonesList={wrongPhonesList} setWrongPhonesList={setWrongPhonesList} />
        </Form.Item>

        <h3 className={styles.title}>{t('modules.add_users_modal.h3')}</h3>
        <Form.Item
          name="couponsNumber"
          className={styles.item}
          rules={[{ required: true, message: t('modules.add_users_modal.form_item.1')! }]}
        >
          <Input id="couponsNumber" type="number" min={1} size="large" className={styles.numberInput} />
        </Form.Item>

        <div className={styles.modalFooter}>
          <Button
            id="AddUsersModalIssueCouponsButton"
            type="primary"
            size="large"
            className={styles.modalSaveButton}
            htmlType="submit"
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? <LoadingOutlined /> : t('modules.add_users_modal.button')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddUsersModal;
