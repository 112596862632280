import { withHandlers } from 'recompose';
import uuidv1 from 'uuid/v1';
import { prop, find, propEq, assoc, assocPath, compose, map } from 'ramda';
import { GET_PROMOCODES } from '../../PromoList/components/PromoBody/graphql';
import pathOr from 'ramda/es/pathOr';
import { message } from 'antd';
import propOr from 'ramda/es/propOr';
import drop from 'ramda/es/drop';

import { promoBounds } from '../../../../../constants';
import dayjs from 'dayjs';
import { t } from 'i18next';

export const withFormikHandlers = withHandlers({
  handleAddPromocode: (props) => async (values) => {
    // The first step is define elementary properties
    const initialSchema = {
      title: values.title,
      maxUseCounts: parseInt(values.saleCount),
      maxUseCountsPerUser: parseInt(values.maxUseCountsPerUser),
      period: {
        start: null,
        end: null,
      },
      status: 'NOT_ACTIVE',
      commentary: values.comment,
      discount: {
        type: '',
        value: values.saleValue,
      },
      adminUseOnly: false,
      useOn: 'ORDER',
      sectorBound: {
        sectorIds: null,
      },
      rowBound: {
        rowIds: null,
      },
      placeBound: {
        placeIds: null,
      },
      tournamentBound: {
        seasonId: null,
        tournamentIds: null,
      },
      stageBound: {
        seasonId: null,
        stageIds: null,
      },
      seasonBound: {
        seasonIds: null,
      },
      priceBound: {
        priceRange: {
          start: null,
          end: null,
        },
      },
      itemsNumberRange: {
        start: null,
        end: null,
      },
      orderItemTypeBound: {
        types: null,
      },
      userBound: {
        userIds: null,
      },
      orderItemBound: {
        items: null,
      },
      isModalVisible: false,
    };

    // Next one is calculated properties
    const getPeriod = (obj) => {
      const period = {
        start: values.periodType === '1' || values.period === null ? null : values.period[0],
        end: values.periodType === '1' || values.period === null ? null : values.period[1],
      };
      return assoc('period', period, obj);
    };

    const getDiscount = (obj) => {
      let type = values.saleType;
      return assocPath(['discount', 'type'], type, obj);
    };

    const getUseOn = (obj) => {
      const type = values.useOn;
      return assocPath(['useOn'], type, obj);
    };

    const getStatus = (obj) => {
      return assocPath(['status'], 'ACTIVE', obj);
    };

    const getAdminUseOnly = (obj) => {
      const adminUseOnly = values.userType === '0' ? false : true;
      return assoc('adminUseOnly', adminUseOnly, obj);
    };

    const getSectorBound = (obj) => {
      const sectorIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '2')))(values.bounds);
      if (sectorIds.length) {
        return assocPath(['sectorBound', 'sectorIds'], sectorIds, obj);
      }
      return assocPath(['sectorBound'], null, obj);
    };

    const getRowBound = (obj) => {
      const rowIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '1')))(values.bounds);
      if (rowIds.length) {
        return assocPath(['rowBound', 'rowIds'], rowIds, obj);
      }
      return assocPath(['rowBound'], null, obj);
    };

    const getPlaceBound = (obj) => {
      const placeIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '0')))(values.bounds);
      if (placeIds.length) {
        return assocPath(['placeBound', 'placeIds'], placeIds, obj);
      }
      return assocPath(['placeBound'], null, obj);
    };

    const getTournamentBound = (obj) => {
      const tournamentIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '3')))(values.bounds);
      const seasonId = compose(pathOr('', ['season', 'id']), find(propEq('type', '3')))(values.bounds);
      if (tournamentIds.length) {
        return compose(
          assocPath(['tournamentBound', 'tournamentIds'], tournamentIds),
          assocPath(['tournamentBound', 'seasonId'], seasonId)
        )(obj);
      }
      return assocPath(['tournamentBound'], null, obj);
    };

    const getStageBound = (obj) => {
      const stageIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '4')))(values.bounds);
      const seasonId = compose(pathOr('', ['season', 'id']), find(propEq('type', '3')))(values.bounds);
      if (stageIds.length) {
        return compose(
          assocPath(['stageBound', 'stageIds'], stageIds),
          assocPath(['stageBound', 'seasonId'], seasonId)
        )(obj);
      }
      return assocPath(['stageBound'], null, obj);
    };

    const getSeasonBound = (obj) => {
      const seasonIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '5')))(values.bounds);
      if (seasonIds.length) {
        return assocPath(['seasonBound', 'seasonIds'], seasonIds, obj);
      }
      return assocPath(['seasonBound'], null, obj);
    };

    const getPriceBound = (obj) => {
      const dataPrices = values.bounds.find((item) => item.type === '6');
      const prices =
        pathOr([], ['values'], dataPrices).length === 0
          ? pathOr([], ['value'], dataPrices)
          : pathOr([], ['values'], dataPrices);
      if (prices.length === 2) {
        const price = {
          start: isNaN(parseInt(prices[0].title).toFixed(2)) ? null : parseInt(prices[0].title).toFixed(2),
          end: isNaN(parseInt(prices[1].title).toFixed(2)) ? null : parseInt(prices[1].title).toFixed(2),
        };
        return assocPath(['priceBound', 'priceRange'], price, obj);
      } else if (prices.length === 1 && prices !== '∞') {
        const parsValue = prices[0].split(' ');
        const price = {
          start: parsValue[1].slice(0, -1),
          end: parsValue[3].slice(0, -1),
        };
        return assocPath(['priceBound', 'priceRange'], price, obj);
      }
      return assocPath(['priceBound'], null, obj);
    };

    const getOrderItemTypeBound = (obj) => {
      const orderItemTypeIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '7')))(values.bounds);
      if (orderItemTypeIds.length) {
        return assocPath(['orderItemTypeBound', 'types'], orderItemTypeIds, obj);
      }
      return assocPath(['orderItemTypeBound'], null, obj);
    };

    const getUserBound = (obj) => {
      const userIds = compose(map(prop('id')), propOr([], 'values'), find(propEq('type', '8')))(values.bounds);
      if (userIds.length) {
        return assocPath(['userBound', 'userIds'], userIds, obj);
      }
      return assocPath(['userBound'], null, obj);
    };

    const getItemsNumberRangeBound = (obj) => {
      const numbers = values.bounds.find((item) => item.type === '9');
      const numValues = pathOr([], ['values'], numbers);
      const numValue = pathOr([], ['value'], numbers);
      const isNumValues = numValues.length === 0;

      if (!isNumValues) {
        const number = {
          start: parseInt(numValues[0].title),
          end: parseInt(numValues[1].title),
        };
        return assocPath(['itemsNumberRange'], number, obj);
      } else if (isNumValues && numValue.length > 0) {
        const parsValue = numValue[0].split(' ');
        const number = {
          start: Number(parsValue[1]),
          end: Number(parsValue[3]),
        };
        return assocPath(['itemsNumberRange'], number, obj);
      }
      return assocPath(['itemsNumberRange'], null, obj);
    };

    const getOrderItemBound = (obj) => {
      const data = values.bounds.find((item) => item.type === '10');
      const itemValues = pathOr([], ['values'], data).map((item) => ({
        type: item.type,
        descriptorIds: [item.id],
      }));

      if (itemValues.length) {
        return assocPath(['orderItemBound', 'items'], itemValues, obj);
      }
      return assocPath(['orderItemBound'], null, obj);
    };

    // Update initial
    const resultObj = compose(
      getPeriod,
      getDiscount,
      getUseOn,
      getAdminUseOnly,
      getStatus,
      getSectorBound,
      getRowBound,
      getPlaceBound,
      getTournamentBound,
      getStageBound,
      getSeasonBound,
      getPriceBound,
      getOrderItemTypeBound,
      getUserBound,
      getItemsNumberRangeBound,
      getOrderItemBound
    )(initialSchema);
    resultObj.isModalVisible = undefined;
    //Update promocode
    if (props.isEditingMode) {
      const promoId = pathOr('', ['defaultPromo', 'id'], props);
      try {
        if (values.period === null && values.periodType === '0') {
          throw new Error();
        }
        props.updatePromocode({
          variables: {
            id: promoId,
            data: resultObj,
          },
        });
        const res = await props.client.query({
          query: GET_PROMOCODES,
        });
        const list = pathOr([], ['data', 'promocodes', 'getList', 'list'], res);
        const oldPromoId = props.defaultPromo.id;
        const newList = list.map((item) => {
          if (item.id === oldPromoId) {
            resultObj.codes = item.codes;
            resultObj.id = item.id;
            resultObj.isActive = item.isActive;
            resultObj.itemsNumberRange = item.itemsNumberRange;
            resultObj.__typename = item.__typename;
            return resultObj;
          } else {
            return item;
          }
        });
        props.formatData(newList);
        message.success(t('modules.with_handlers_promo_add.message_success.updated_successfully'));
        props.setIsAddVisible(false);
      } catch (e) {
        message.error(t('modules.with_handlers_promo_add.message_error.failed_update'));
      }
    }
    //Create descriptor
    if (!props.isEditingMode) {
      const descriptorData = async () => {
        try {
          return await props.createPromoDescriptor({
            variables: {
              data: resultObj,
            },
          });
        } catch {
          return false;
        }
      };

      const descriptorId = pathOr('', ['data', 'promocodes', 'add', 'id'], await descriptorData());

      // Add codes if prefix
      if (values.promoType === '0') {
        try {
          const res = await props.client.query({
            query: GET_PROMOCODES,
          });
          const list = pathOr([], ['data', 'promocodes', 'getList', 'list'], res);

          await props.addPrefixPromo({
            variables: {
              data: {
                prefix: values.promoPrefixValue,
                quantity: parseInt(values.promoCount),
                descriptorId,
              },
            },
            update: (_, { data }) => {
              const resObj = pathOr({}, ['promocodes', 'generatePromocodes'], data);
              props.formatData([resObj, ...list], false);
            },
          });
          message.success(t('modules.with_handlers_promo_add.message_success.successfully_generated'));
          props.setIsAddVisible(false);
        } catch {
          message.error(t('modules.with_handlers_promo_add.message_error.failed_generating'));
        }
      }
      // Without prefix
      if (values.promoType === '1') {
        try {
          await props.addPromoCodes({
            variables: {
              data: {
                values: [values.promoValue],
                descriptorId,
              },
            },
            update: (_, { data }) => {
              const currList = props.promoList;
              const resObj = pathOr({}, ['promocodes', 'addPromocodesToDescriptor'], data);
              const resArr = props.formatData([resObj], false);
              const curr = drop(1, currList);
              props.setPromoList([
                {
                  title: {
                    isGroupTitle: true,
                    title: 'Group: Test',
                  },
                  sale: {
                    isGroupTitle: true,
                  },
                  period: {
                    isGroupTitle: true,
                  },
                  activations: {
                    isGroupTitle: true,
                  },
                  group: {
                    isGroupTitle: true,
                  },
                  code: {
                    isGroupTitle: true,
                  },
                  action: {
                    isGroupTitle: true,
                  },
                },
                ...resArr,
                ...curr,
              ]);
            },
          });
          message.success(t('modules.with_handlers_promo_add.message_success.successfully_created'));
          props.setIsAddVisible(false);
        } catch {
          message.error(t('modules.with_handlers_promo_add.message_error.failed_creating'));
        }
      }
    }
  },
  formatPromoData: (props) => (initialObj) => {
    const formikObj = {
      title: '',
      groupType: '0',
      groupTitle: '',
      promoType: '0',
      promoPrefixValue: '',
      promoValue: '',
      promoCount: '100',
      saleValue: '',
      saleType: 'PERCENT',
      saleCount: '1',
      maxUseCountsPerUser: '1',
      periodType: '0',
      period: [null, null],
      userType: '0',
      useOn: 'ITEM',
      comment: '',
      bounds: [],
      currentBound: undefined,
      boundTypeList: promoBounds,
      isModalVisible: false,
    };
    const saleType = pathOr('', ['discount', 'type'], initialObj);

    const periodStart = new Date(initialObj.period?.start);
    const periodEnd = new Date(initialObj.period?.end);

    const initialPeriod = pathOr(null, ['period'], initialObj);
    const periodValue = [dayjs(periodStart), dayjs(periodEnd)];
    const userType = pathOr(false, ['adminUseOnly'], initialObj) ? '1' : '0';
    if (initialObj) {
      const bounds = props.getPromoBounds(initialObj);
      return compose(
        assoc('title', pathOr('', ['title'], initialObj)),
        assoc('saleType', saleType),
        assoc('saleCount', pathOr('0', ['maxUseCounts'], initialObj)),
        assoc('maxUseCountsPerUser', pathOr('1', ['maxUseCountsPerUser'], initialObj)),
        assoc('periodType', initialPeriod.start || initialPeriod.end ? '0' : '1'),
        assoc('period', periodValue),
        assoc('userType', userType),
        assoc('bounds', bounds),
        assoc('saleValue', pathOr('', ['discount', 'value'], initialObj)),
        assoc('useOn', pathOr('', ['useOn'], initialObj)),
        assoc('comment', pathOr('', ['commentary'], initialObj)),
        assoc('isModalVisible', pathOr(false, ['isModalVisible'], initialObj))
      )(formikObj);
    }
    return formikObj;
  },
});

export default withHandlers({
  handleBoundPositionsSelect: (props) => (bound) => {
    props.setFieldValue('currentBound', bound);
    props.setFieldValue('isModalVisible', true);
  },
  handleModalClose: (props) => () => {
    props.setFieldValue('isModalVisible', false);
  },
  handleAddNewBound: ({ setFieldValue, values }) => () => {
    setFieldValue('bounds', [
      ...values.bounds,
      {
        id: uuidv1(),
        type: '0',
        condition: '0',
        values: [],
      },
    ]);
  },
});
