import React from 'react';
import './Buttons.scss';
import ModalWarning from '../ModalWarning/ModalWarning';

import { Button } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { useTranslation } from 'react-i18next';

const Buttons = (props: {
  id: string;
  loading: boolean;
  handleCancelNewSegment: () => void;
  handleDeleteSegment: () => void;
  isCreatingSegment: boolean;
}) => {
  const { t } = useTranslation();
  const handleDeleteSegmentToModal = () => {
    props.handleDeleteSegment();
  };

  return (
    <div className="segments__segment-buttons">
      {props.isCreatingSegment ? (
        <Button
          id={`${props.id}CancelButton`}
          danger
          size="large"
          className="segments__segment-button-cancel"
          onClick={() => props.handleCancelNewSegment()}
        >
          {t('modules.buttons.0')}
        </Button>
      ) : (
        <ModalWarning
          title={t('modules.buttons.1')}
          onConfirmClick={handleDeleteSegmentToModal}
          buttonText={t('modules.buttons.2')}
          confirmationButtonText={t('modules.buttons.3')}
        />
      )}

      <Button
        id={`${props.id}CreateSaveButton`}
        type="primary"
        size="large"
        className="segments__segment-button-save"
        htmlType="submit"
        disabled={props.loading}
      >
        {props.isCreatingSegment ? t('modules.buttons.4') : t('modules.buttons.5')}
      </Button>
    </div>
  );
};

export default Buttons;
