import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import ApolloClient from 'apollo-client';
import { v4 as uuid4 } from 'uuid';

import './EntriesTable.scss';
import arrowRight from '../../../../img/arrow-right.svg';
import Loader from 'common/Loader/Loader';

import { EntrancePass, EntrancePassList, AccessObjectType } from '../../../../model/usersModel';
import { GET_ENTRANCE_PASS } from '../../../../model/usersQueries';
import { deserializeEntrancePass } from '../../../../model/usersDeserializers';

import { Pagination } from 'utils/commonTypes';
import { deserialize, fetchData } from 'utils/apiHelpers';
import { useTranslation } from 'react-i18next';

const EntriesTable = (props: { userId: string; apolloClient: ApolloClient<any> }) => {
  const { t } = useTranslation();
  const { Column } = Table;
  const [loading, setLoading] = useState<boolean>(false);
  const [entrancePass, setEntrancePass] = useState<EntrancePass>();

  const defaultPagination = { limit: 10, page: 1 };
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  function getEntrancePass(userId: string, pagination: Pagination) {
    fetchData(
      loading,
      setLoading,
      props.apolloClient.query({
        query: GET_ENTRANCE_PASS,
        variables: { userIds: [userId], pagination: { limit: pagination.limit, page: pagination.page - 1 } },
        fetchPolicy: 'no-cache',
      }),
      setEntrancePass,
      (res) =>
        deserialize(res, (o) => o.required('data.acs.entrancePass.getList').asObject((o) => deserializeEntrancePass(o)))
    );
  }

  useEffect(() => {
    setPagination(defaultPagination);
  }, [props.userId]);

  useEffect(() => {
    getEntrancePass(props.userId, pagination);
  }, [pagination]);

  function createNewPagination(page?: number): Pagination {
    return {
      ...pagination,
      page: page ? page : 1,
    };
  }

  function onPageChange(page?: number) {
    const newPagination = createNewPagination(page);
    setPagination(newPagination);
  }

  const PRODUCT_TYPE = {
    [AccessObjectType.ACCREDITATION]: t('modules.entries_table.product_type.0'),
    [AccessObjectType.TICKET]: t('modules.entries_table.product_type.1'),
    [AccessObjectType.SEASON_TICKET]: t('modules.entries_table.product_type.2'),
    [AccessObjectType.VIP_INVITE]: t('modules.entries_table.product_type.3'),
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Table
          dataSource={entrancePass?.list}
          rowKey={() => uuid4()}
          pagination={{
            total: entrancePass?.total,
            onChange: onPageChange,
            current: pagination.page,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          className="components-table-demo-nested"
          style={{ width: '100%', marginTop: '-16px' }}
        >
          <Column
            title={t('modules.entries_table.column.0')}
            key="events.title"
            align="left"
            render={(dataSource: EntrancePassList) => {
              return dataSource.events.map((item: any) => item.title).join(', ');
            }}
            className="block-column"
          />
          <Column
            title={t('modules.entries_table.column.1')}
            dataIndex="accessObjectTitle"
            align="left"
            className="block-column"
          />
          <Column
            title={t('modules.entries_table.column.2')}
            key="entrance.title"
            align="left"
            render={(dataSource: EntrancePassList) => dataSource.entrance.title}
            className="block-column"
          />
          <Column
            title={t('modules.entries_table.column.3')}
            key="entrance.zonesIn"
            align="left"
            render={(dataSource: EntrancePassList) => {
              return dataSource.direction === 'ENTER'
                ? dataSource.entrance.zonesOut.map((item: any) => item.title).join(', ')
                : dataSource.entrance.zonesIn.map((item: any) => item.title).join(', ');
            }}
            className="block-column"
          />
          <Column
            width="16px"
            className="column-array"
            align="left"
            render={() => (
              <img src={arrowRight} style={{ fontSize: '16px' }} alt={t('modules.entries_table.column.4')!} />
            )}
          />
          <Column
            title={t('modules.entries_table.column.5')}
            key="entrance.zonesOut"
            align="left"
            render={(dataSource: EntrancePassList) => {
              return dataSource.direction === 'ENTER'
                ? dataSource.entrance.zonesIn.map((item: any) => item.title).join(', ')
                : dataSource.entrance.zonesOut.map((item: any) => item.title).join(', ');
            }}
            className="block-column"
          />
          <Column
            title={t('modules.entries_table.column.6')}
            key="createdTime"
            align="left"
            render={(dataSource: EntrancePassList) =>
              dataSource.dateTime.toLocaleString('ru-Ru', { dateStyle: 'short', timeStyle: 'short' })
            }
            className="block-column"
          />
          <Column
            title={t('modules.entries_table.column.7')}
            key="accessObjectType"
            align="left"
            render={(dataSource: EntrancePassList) => PRODUCT_TYPE[dataSource.accessObjectType]}
            className="block-column"
          />
          <Column
            title={t('modules.entries_table.column.8')}
            key="isOffline"
            align="left"
            render={(dataSource: EntrancePassList) =>
              dataSource.isOffline ? t('modules.entries_table.column.9') : t('modules.entries_table.column.10')
            }
            className="block-column"
          />
          {/*<Column*/}
          {/*  title={t('modules.entries_table.column.11')}*/}
          {/*  align='left'*/}
          {/*  render={(dataSource) => {*/}
          {/*    switch (dataSource.status) {*/}
          {/*      case 'PAYED':*/}
          {/*        return (*/}
          {/*          <span>*/}
          {/*            <Badge color='#FF4747' className='subtable-title__badge'/>*/}
          {/*            {t('modules.entries_table.span.0')}*/}
          {/*          </span>);*/}
          {/*      case 'UNPAYED':*/}
          {/*        return (*/}
          {/*          <span>*/}
          {/*            <Badge color='#03B94E' className='subtable-title__badge'/>*/}
          {/*            {t('modules.entries_table.span.1')}*/}
          {/*          </span>);*/}
          {/*    }*/}
          {/*  }}*/}
          {/*/>*/}
        </Table>
      )}
    </>
  );
};

export default EntriesTable;
