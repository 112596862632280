import { withFormik } from 'formik';
import { pathOr, compose, propEq, find, prop } from 'ramda';
import { message } from 'antd';
import map from 'ramda/es/map';
import when from 'ramda/es/when';
import merge from 'ramda/es/merge';
import { GET_USERS } from '../../../graphql';
import { t } from 'i18next';

export default withFormik({
  mapPropsToValues: (props) => {
    const user = (path) => pathOr('', ['user', 'person', ...path], props);
    const emailType = propEq('type', 'EMAIL');
    const phoneType = propEq('type', 'PHONE');
    const value = prop('value');
    const email = compose(value, find(emailType))(user(['contacts']));
    const phone = compose(value, find(phoneType))(user(['contacts']));
    return {
      email,
      name: user(['name', 'ru']),
      surname: user(['surname', 'ru']),
      patronymic: user(['patronymic', 'ru']),
      birthday: user(['birthday']),
      phone,
    };
  },
  enableReinitialize: true,
  handleSubmit: async (values, { props }) => {
    const id = pathOr('', ['user', 'id'], props);
    const user = props.getUser(values);
    try {
      props.setIsSubmitButtonDisabled(true);
      await props.updateUser({
        variables: {
          id,
          user,
        },
        update: (cache) => {
          const cacheData = cache.readQuery({
            query: GET_USERS,
          });
          const list = pathOr([], ['users', 'getList'], cacheData);
          const updatedList = compose(map(when(propEq('id', id), merge(user))))(list);
          cache.writeQuery({
            query: GET_USERS,
            data: {
              users: {
                getList: updatedList,
              },
            },
          });
        },
      });
      message.success(t('modules.with_formik.0'));
      props.onCancel();
    } catch (e) {
      message.error(t('modules.with_formik.1'));
    } finally {
      props.setIsSubmitButtonDisabled(false);
    }
  },
});
