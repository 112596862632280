import React from 'react';
import {
  Coupon,
  ItemType,
  OrderItemConnection,
  PriceBound,
  Season,
  SectorBound,
  StageBound,
  Stages,
  TournamentBound,
  Tournaments,
} from '../model/discountsModel';

import { NBS } from '../../../../../constants';
import { formatFraction } from 'utils/apiHelpers';
import { NamedEntity } from 'utils/commonTypes';
import { t } from 'i18next';

const formatTournament = (tournament: TournamentBound) => {
  return (
    <span className="discounts__condition" key="tournamentBound">
      {t('modules.discount_bound_maker.span.0')} {tournament.season.startDate.getUTCFullYear()}/
      {tournament.season.endDate.getUTCFullYear()} {t('modules.discount_bound_maker.span.1')}{' '}
      {tournament.tournaments.map((tournament: Tournaments) => tournament.title).join(', ')}
    </span>
  );
};

const formatStage = (stage: StageBound) => {
  return (
    <span className="discounts__condition" key="stageBound">
      {t('modules.discount_bound_maker.span.0')} {stage.season.startDate.getUTCFullYear()}/
      {stage.season.endDate.getUTCFullYear()} {t('modules.discount_bound_maker.span.1')}{' '}
      {stage.stages.map((stage: Stages) => stage.title).join(', ')}
    </span>
  );
};

const formatSeason = (seasons: Season[]) => {
  return (
    <span className="discounts__condition" key="season">
      {seasons
        .map(
          (season: Season) =>
            `— ${t(
              'modules.discount_bound_maker.span.0'
            )} ${season.startDate.getUTCFullYear()}/${season.endDate.getUTCFullYear()}`
        )
        .join(', ')}
    </span>
  );
};

const singularOrPluralSector = (arrLength: number) => {
  switch (arrLength) {
    case 1:
      return t('modules.discount_bound_maker.singular_or_plural_sector.0');
    default:
      return t('modules.discount_bound_maker.singular_or_plural_sector.1');
  }
};
const formatSector = (sector: SectorBound) => {
  return (
    <span className="discounts__condition" key="sectorBound">
      {t('modules.discount_bound_maker.span.2')} {singularOrPluralSector(sector.sectorIds.length)}{' '}
      {sector.sectorIds.map((sector: string) => sector).join(', ')}
    </span>
  );
};

const formatPrice = (price: PriceBound) => {
  const priceBoundStart =
    price?.start && price?.start !== '0.00'
      ? `${t('modules.discount_bound_maker.format_price.0')}${NBS}${Number(
          formatFraction(price.start)
        ).toLocaleString()}${NBS}${t('currency')}`
      : '';
  const priceBoundEnd =
    price?.end && price?.end !== '0.00'
      ? `${t('modules.discount_bound_maker.format_price.1')}${NBS}${Number(
          formatFraction(price.end)
        ).toLocaleString()}${NBS}${t('currency')}`
      : '';

  return (
    (price?.start !== undefined || price?.end !== undefined) && (
      <span className="discounts__condition" key="priceBound">
        {t('modules.discount_bound_maker.span.3')} {priceBoundStart} {priceBoundEnd}
      </span>
    )
  );
};

const ITEM_TYPE_MAP = {
  TICKET: t('modules.discount_bound_maker.item_type_map.0'),
  SEASON_TICKET: t('modules.discount_bound_maker.item_type_map.1'),
  PROLONG_SEASON_TICKET: t('modules.discount_bound_maker.item_type_map.2'),
  OUTFIT: t('modules.discount_bound_maker.item_type_map.3'),
};
const formatOrderItemType = (itemType: ItemType[]) => {
  return (
    <span className="discounts__condition" key="itemType">
      {t('modules.discount_bound_maker.span.2')} {itemType.map((item: ItemType) => ITEM_TYPE_MAP[item]).join(', ')}
    </span>
  );
};

const ORDER_ITEM_TYPE_MAP = {
  TICKET: t('modules.discount_bound_maker.order_item_type_map.0'),
  SEASON_TICKET: t('modules.discount_bound_maker.order_item_type_map.1'),
  PROLONG_SEASON_TICKET: t('modules.discount_bound_maker.order_item_type_map.2'),
  OUTFIT: t('modules.discount_bound_maker.order_item_type_map.3'),
};
const formatOrderItem = (orderItem: OrderItemConnection[]) => {
  return (
    <span className="discounts__condition" key="orderItemConnection">
      {t('modules.discount_bound_maker.span.2')}{' '}
      {orderItem
        .map(
          (orderItem: OrderItemConnection) =>
            `${ORDER_ITEM_TYPE_MAP[orderItem.type]} ${orderItem.items.map((item: NamedEntity) => item.title)}`
        )
        .join(', ')}
    </span>
  );
};

export default function discountBoundMaker(couponsData: Coupon) {
  const discountsArray = [
    couponsData.tournamentBound && formatTournament(couponsData.tournamentBound),
    couponsData.stageBound && formatStage(couponsData.stageBound),
    couponsData.seasonBound && formatSeason(couponsData.seasonBound),
    couponsData.sectorBound && formatSector(couponsData.sectorBound),
    couponsData.priceBound && formatPrice(couponsData.priceBound),
    couponsData.orderItemTypeBound && formatOrderItemType(couponsData.orderItemTypeBound.types),
    couponsData.orderItemBound && formatOrderItem(couponsData.orderItemBound),
  ];
  const result = discountsArray.map((item) => item).filter((item) => item);

  return <span style={{ whiteSpace: 'pre-line' }}>{result}</span>;
}
