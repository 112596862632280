import React, { useState } from 'react';
import eye from '../img/eyePassword.svg';
import eyeClose from '../img/eyePasswordClose.svg';
import { Button } from 'antd';
import InputMask from 'react-input-mask';
import { message } from 'antd';

import '../style.scss';
import { useTranslation } from 'react-i18next';

const LoginForm = (props) => {
  const { t } = useTranslation();
  const [stateProps, setState] = useState({ userPhone: '', userPassword: '' });
  const [showPassword, setShowPassword] = useState(false);

  const proceedLogin = async (ev) => {
    try {
      await props.authClient.login(stateProps.userPhone, stateProps.userPassword);
      window.location.href = window.location.origin + '/admin';
    } catch (e) {
      if (e.message.includes('BAD_CREDENTIALS')) {
        message.error(t('modules.auth_components_index.message_error'));
      }
      console.error(e);
    }
  };

  return (
    <div className="login">
      {window.parametrize('REACT_APP_ADDITIONAL_LOGO_SRC')
        ? <div className="login__logo">
          <img src={window.parametrize('REACT_APP_ADDITIONAL_LOGO_SRC')} alt={t('modules.auth_components_index.img')}/>
        </div>
        : <span className="login__logo login__logo__text">{window.parametrize('REACT_APP_ADDITIONAL_LOGO_TEXT')}</span>
      }
      <div className="login__wrapper">
        <h4 className={'login__title'}>{t('modules.auth_components_index.h4')}</h4>
        <div className={'login__data'}>
          <label className={'login__label'}>
            <span>{t('modules.auth_components_index.span.0')}</span>

            <InputMask
              type="tel"
              size="large"
              name="phoneNumber"
              placeholder={t('modules.auth_components_index.input_mask')}
              mask="+7 999 999-99-99"
              maskChar={null}
              id="login_login"
              className="login__input login__telephone-input"
              onKeyPress={async (ev) => {
                if (ev.key === 'Enter') {
                  await proceedLogin();
                }
              }}
              onChange={(ev) => {
                setState({
                  ...stateProps,
                  userPhone: ev.target.value.match(/\d+/gi).join(''),
                  // .replace(/([\+\s()_-])/g, '')
                });
              }}
            />
          </label>

          <label className={'login__label'}>
            <span>{t('modules.auth_components_index.span.1')}</span>

            <input
              className="login__input login__password-input"
              placeholder={t('modules.auth_components_index.input')}
              type={showPassword ? 'text' : 'password'}
              id="password_password"
              onKeyPress={async (ev) => {
                if (ev.key === 'Enter') {
                  await proceedLogin();
                }
              }}
              onChange={(ev) => {
                setState({ ...stateProps, userPassword: ev.target.value });
              }}
            />
            <Button id="LoginFormShowHideButton" className={'login__button_password'} onClick={() => setShowPassword(!showPassword)}>
              <img src={showPassword ? eyeClose : eye} alt={'show/hide password'} />
            </Button>
          </label>
          <Button
            id="LoginFormSignInButton"
            type="primary"
            className="login__button login__proceed-button person-info__btn-exit"
            block
            onClick={async (ev) => {
              await proceedLogin();
            }}
          >
            {t('modules.auth_components_index.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
