import { useEffect } from 'react';

/**
 * Loads data and puts it in state. If necessary, passes the received data through the filter
 * @param loading - boot flag
 * @param setLoading - load flag state update function
 * @param fetch - asynchronous request function
 * @param setContent - the function takes the result of the request and puts it in the State
 * @param filter - optional filter for processing data received from the request
 */
export async function fetchData<T, R>(
  loading: boolean,
  setLoading: (value: boolean) => void,
  fetch: Promise<T | R>,
  setContent: (value: R) => void,
  filter?: (res: T) => R
): Promise<void> {
  if (loading) return;
  setLoading(true);
  try {
    const res = await fetch;
    filter ? setContent(filter(res as T)) : setContent(res as R);
  } catch (e) {
    console.error(e);
  } finally {
    setLoading(false);
  }
}

export async function sendData<T, R>(
  loading: boolean,
  setLoading: (value: boolean) => void,
  send: Promise<any>,
  onSuccess?: () => void,
  onError?: () => void,
  onFinish?: () => void,
  setContent?: (value: R) => void,
  filter?: (res: T) => R
): Promise<void> {
  if (loading) return;
  setLoading(true);
  try {
    const res = await send;
    setContent && setContent(filter ? filter(res as T) : (res as R));
    onSuccess && onSuccess();
  } catch (e) {
    console.error(e);
    onError && onError();
  } finally {
    if (onFinish) onFinish();
  }
}

/**
 * Executes a block of code when the component appears
 * @param block
 */
export function useDidMount(block: () => void) {
  useEffect(() => {
    block();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export type Path = string[] | string;

export function parsePath(path: Path): string[] {
  if (typeof path === 'string') {
    return path.split('.');
  } else {
    return path;
  }
}

export type Mapper<T, R> = (v: T, path: string[]) => R;

export type DTO = Record<string, any>;

class DeserializationPath {
  constructor(private path?: string[]) {}

  append(path: string[]): DeserializationPath {
    return new DeserializationPath((this.path || []).concat(path));
  }

  toString() {
    return this.path?.join('.') || '';
  }
}

export class DeserializationError extends Error {
  constructor(path: DeserializationPath, message?: string) {
    super(`Deserialization error on path=${path.toString()}: ${message}`);
  }
}

class ValueWrapper {
  constructor(private path: DeserializationPath, private value: any) {}

  get asAny(): any {
    return this.value;
  }

  get asBool(): boolean {
    if (typeof this.value !== 'boolean') throw new DeserializationError(this.path, 'Not a boolean');
    return this.value;
  }

  get asString(): string {
    if (typeof this.value !== 'string') throw new DeserializationError(this.path, 'Not a string');
    return this.value;
  }

  get asNumber(): number {
    if (typeof this.value !== 'number') throw new DeserializationError(this.path, 'Not a number');
    return this.value;
  }

  get asDate(): Date {
    if (typeof this.value !== 'string') throw new DeserializationError(this.path, 'Not a string');
    const date = new Date(this.value);
    if (!date.getDate()) throw new DeserializationError(this.path, 'Not a date');
    return new Date(this.value);
  }

  as<T>(mapper: (value: any, path: DeserializationPath) => T): T {
    return mapper(this.value, this.path);
  }

  asObject<T>(mapper: (obj: ObjectDeserializator) => T): T {
    const obj = new ObjectDeserializator(this.value, this.path);
    return mapper(obj);
  }

  asArray<T>(mapper: (value: any, path: DeserializationPath) => T): T[] {
    if (!Array.isArray(this.value)) throw new DeserializationError(this.path, 'Not an array');
    return this.value.map((v) => mapper(v, this.path));
  }

  asArrayOfObjects<T>(mapper: (obj: ObjectDeserializator) => T): T[] {
    return this.asArray((v, p) => mapper(new ObjectDeserializator(v, p)));
  }
}

export class ObjectDeserializator {
  private path: DeserializationPath;

  constructor(private obj: any, path?: DeserializationPath) {
    this.path = path || new DeserializationPath();
    if (typeof obj !== 'object' || Array.isArray(obj)) throw new DeserializationError(this.path, 'Not an object');
  }

  private followPath(obj: any, path: string[]): any {
    let currentPath = this.path;
    for (const key of path) {
      if (obj === undefined || obj === null) return undefined;
      if (typeof obj !== 'object') throw new DeserializationError(currentPath, `Not an object`);
      obj = obj[key];
      currentPath = currentPath.append([key]);
    }
    return obj;
  }

  optional(paramPath: Path): ValueWrapper | undefined {
    const path = parsePath(paramPath);
    const v = this.followPath(this.obj, path);
    if (v === null || v === undefined) return undefined;
    return new ValueWrapper(this.path.append(path), v);
  }

  required(paramPath: Path): ValueWrapper {
    const path = parsePath(paramPath);
    const v = this.followPath(this.obj, path);
    if (v === null || v === undefined) throw new DeserializationError(this.path.append(path), 'Value required');
    return new ValueWrapper(this.path.append(path), v);
  }
}

export function deserialize<T>(obj: any, mapper: (obj: ObjectDeserializator) => T): T {
  const deserializator = new ObjectDeserializator(obj);
  return mapper(deserializator);
}

// The function takes a number and removes 00 after the dot if there are no tenths
export function formatFraction(number: string) {
  let [integral, fractional] = number.split('.');
  return Number(fractional) === 0 ? integral : number;
}

// The function takes a number and adds a + if the number is positive and changes the hyphen to a minus if it's negative
export function formatSign(value: string) {
  return Number(value) < 0 ? value.replace('-', '−') : `+${value}`;
}
