import { Period } from '../model/discountsModel';
import { NBS } from '../../../../../constants';
import i18next, { t } from 'i18next';

export default function formatPeriod(period: Period) {
  const periodStart = period.start
    ? `${t('modules.format_period.0')} ${new Intl.DateTimeFormat(i18next.language).format(period.start)}`
    : '';
  const periodEnd = period.end
    ? `${t('modules.format_period.1')}${NBS}${new Intl.DateTimeFormat(i18next.language).format(period.end)}`
    : '';

  return `${periodStart} ` + `${periodEnd}`;
}
