import React from 'react';
import '../../../../Loyalty.scss';
import './ModalHoverName.scss';
import payment from './images/payment.png';
import profileTickets from './images/profile-tickets.png';
import { useTranslation } from 'react-i18next';

function ModalHoverName() {
  const { t } = useTranslation();
  return (
    <div className="route-content_loyalty-modal-hover-name">
      <p className="route-content_loyalty-modal-hover-text">
        {t('modules.modal_hover_name.p.0')}&nbsp;{t('modules.modal_hover_name.p.1')}&nbsp;
        {t('modules.modal_hover_name.p.2')}:
      </p>

      <div className="route-content_loyalty-examples">
        <div className="example-profile-tickets">
          <img
            src={profileTickets}
            className="example-profile-tickets__image"
            alt={t('modules.modal_hover_name.img')!}
          />
        </div>

        <div className="example-payment">
          <img src={payment} className="example-payment__image" alt={t('modules.modal_hover_name.img')!} />
        </div>
      </div>

      <p className="route-content_loyalty-modal-hover-text">
        {t('modules.modal_hover_name.p.3')}&nbsp;&mdash; {t('modules.modal_hover_name.p.4')}&nbsp;
        {t('modules.modal_hover_name.p.5')}&nbsp;{t('modules.modal_hover_name.p.6')}&nbsp;
        {t('modules.modal_hover_name.p.7')}
      </p>
    </div>
  );
}

export default ModalHoverName;
