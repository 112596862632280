import { t } from 'i18next';
import { AccreditationRequestStatus } from './modules/accreditation/src/model/accreditationModel';

export const DOWNLOAD = t('modules.constants_app.0');
export const DELETE = t('modules.constants_app.1');
export const ALL_STATUSES = 'ALL_STATUSES';
export const ACTION_GROUP = t('modules.constants_app.2');
export const MESSAGE_TIME_DURATION = 2;
export const ERROR_MESSAGE_TIME_DURATION = 5;
export const NBS = '\xA0'; // Non-Breaking Space (non-breaking space)

export const colors = {
  blue: '#007bff',
  white: '#fff',
  black: '#282828',
  yellow: '#ffc107',
};

export const ITEMS_PER_PAGE = 20;
export const currency = t('currency');
export const promoBounds = [
  t('modules.constants_app.3'),
  t('modules.constants_app.4'),
  t('modules.constants_app.5'),
  t('modules.constants_app.6'),
  t('modules.constants_app.7'),
  t('modules.constants_app.8'),
  t('modules.constants_app.9'),
  t('modules.constants_app.10'),
  t('modules.constants_app.11'),
  t('modules.constants_app.12'),
  t('modules.constants_app.13'),
];

export const allStatuses = [
  AccreditationRequestStatus.PENDING,
  AccreditationRequestStatus.REJECTED,
  AccreditationRequestStatus.ACCEPTED,
];

export const COMMON_REGEXP = {
  ANTHROPONYM_CYRILLIC: `^([a-яёА-ЯЁ]{1,}['-]?[a-яёА-ЯЁ]+)$`,
  ANTHROPONYM_CYRILLIC_COMPLEX: `^([a-яёА-ЯЁ]{1,}['-]?[a-яёА-ЯЁ]+){0,2}$`,
  ANTHROPONYM_LATIN: `^([a-zA-Z]{1,}['-]?[a-zA-Z]+)$`,
  ANTHROPONYM_LATIN_COMPLEX: `^[a-zA-Z]{1,}(['-]?[a-zA-Z]+){0,2}$`,
  PHONE_SPLITTED: `\\(?\\d{3}\\)?\\s?\\d{3}-?\\d{2}-?\\d{2}`,
  EMAIL: `^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z]{2,5}$`,
  ADDRESS_CYRILLIC: `^[а-яёА-ЯЁ0-9 ,.\\-\\/]+$`,
  ADDRESS_LATIN: `^[a-zA-Z0-9 ,.\\-\\/]+$`,
  CYRYLLIC_LETTERS_AND_SYMBOLS: `^[а-яёА-ЯЁ0-9 ,."«»№()\\-]+$`,
  LATIN_LETTERS_AND_SYMBOLS: `^[a-zA-Z0-9 ,."«»№()\\-]+$`,
  PRICE: `^(0|[1-9]\\d*)(\\.\\d{1,2})?$`,
};
