import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import '../../Loyalty.scss';
import './ProgramSettings.scss';
import { MESSAGE_TIME_DURATION } from '../../../constants';
import ModalDeactivationWarning from './modals/ModalDeactivationWarning/ModalDeactivationWarning';
import RouteLeavingGuard from './RouteLeavingGuard/RouteLeavingGuard';
import ModalSaveError from './modals/ModalSaveError/ModalSaveError';
import ModalHoverLegalityDocumentation from './modals/ModalHoverLegalityDocumentation/ModalHoverLegalityDocumentation';
import ModalHoverName from './modals/ModalHoverName/ModalHoverName';

import { Icon } from '@ant-design/compatible';

import { Button, message, Select, Upload } from 'antd';
import { Formik, Field, ErrorMessage, FieldArray, useField } from 'formik';
import * as Yup from 'yup';
import { pathOr } from 'ramda';
import { withApollo } from 'react-apollo';
import ApolloClient from 'apollo-client';

import { FilePublicLinkType, LoyaltyDataType, LoyaltyDataValuesType } from '../../model/loyaltyModel';
import { legalityDocumentationDeserializer, loyaltyCurrencyNameDeserializer } from '../../model/loyaltyDeserializers';
import { GET_FILE_PUBLIC_LINK, GET_LOYALTY_DATA } from '../../model/loyaltyQueries';
import { CHANGE_LOYALTY_RULES, CHANGE_LOYALTY_PROGRAM } from '../../model/loyaltyMutations';

import { deserialize, fetchData, ObjectDeserializator, useDidMount } from 'utils/apiHelpers';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import { useTranslation } from 'react-i18next';

function ProgramSettings(props: {
  apolloClientProps: ApolloClient<any>;
  authClientProps: AuthClient;
  activeProgramState: () => void;
}) {
  const { t } = useTranslation();
  let history = useHistory();
  const { Option } = Select;
  const [loading, setLoading] = useState<boolean>(false);

  const [isSaveError, setIsSaveError] = useState<boolean>(false);

  const [isLegalityDocumentationOnServer, setIsLegalityDocumentationOnServer] = useState<boolean>(false);
  const [isFilledInput, setIsFilledInput] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);

  const [loyaltyData, setLoyaltyData] = useState<LoyaltyDataType>();
  const [filePublicLink, setFilePublicLink] = useState<FilePublicLinkType>();
  const [legalityDocumentationLink, setLegalityDocumentationLink] = useState<null | string>(null);
  const [legalityDocumentationFileId, setLegalityDocumentationFileId] = useState<null | string>(null);

  function getLoyaltyData() {
    fetchData(
      loading,
      setLoading,
      props.apolloClientProps.query({
        query: GET_LOYALTY_DATA,
        fetchPolicy: 'no-cache',
      }),
      setLoyaltyData,
      (res: any) =>
        deserialize(res, (o) =>
          o.required('data.loyalty.loyaltyDescriptorQuery.getDescriptor').asObject((o: any) => ({
            bonusActivePeriodDays: o.optional('bonusActivePeriodDays')?.asNumber || null,
            bonusPriceCoverPercentage: o.required('bonusPriceCoverPercentage').asNumber,
            accrualPercentage: o.required('accrualPercentage').asNumber,
            maxExpenditurePerTransactionPercentage: o.optional('bonusActivePeriodDays')?.asNumber,
            applicableWithDiscount: o.required('applicableWithDiscount').asBool,
            legalityDocumentationLink: o.optional('legalityDocumentationLink')?.asString,
            legalityDocumentation:
              o
                .optional('legalityDocumentation')
                ?.asObject((d: ObjectDeserializator) => legalityDocumentationDeserializer(d)) || null,
            loyaltyCurrencyName: o
              .required('loyaltyCurrencyName')
              .asObject((d: ObjectDeserializator) => loyaltyCurrencyNameDeserializer(d)),
            updatedAt: o.required('updatedAt').asDate,
            isActive: o.required('isActive').asBool,
          }))
        )
    );
  }

  useDidMount(() => {
    getLoyaltyData();
  });

  useEffect(() => {
    if (
      typeof loyaltyData?.legalityDocumentation?.id === 'string' ||
      typeof loyaltyData?.legalityDocumentationLink === 'string'
    ) {
      setIsLegalityDocumentationOnServer(true);
    }
  }, [loyaltyData]);

  const checkLegalityDocumentationLink = (value: string) => {
    if (!isUploaded && !isLegalityDocumentationOnServer) {
      if (
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(
          value
        )
      ) {
        setIsFilledInput(true);
        setLegalityDocumentationLink(value);
        return true;
      }
    } else if (isUploaded || isLegalityDocumentationOnServer) {
      return true;
    }
    return false;
  };

  const checkLegalityDocumentation = (value: object[]) => {
    if (!isFilledInput && !isLegalityDocumentationOnServer) {
      if (value !== undefined) {
        // Array.isArray(value)
        return true;
      }
    } else if (isFilledInput || isLegalityDocumentationOnServer) {
      return true;
    }
    return false;
  };

  const validationSchema = Yup.object().shape({
    legalityDocumentation: Yup.array().test(
      'legalityDocumentation',
      t('modules.program_settings.validation_schema.0')!,
      checkLegalityDocumentation
    ),
    legalityDocumentationLink: Yup.string().test(
      'legalityDocumentationLink',
      t('modules.program_settings.validation_schema.0')!,
      checkLegalityDocumentationLink
    ),
    loyaltyCurrencyNameMod1: Yup.string().required(t('modules.program_settings.validation_schema.1')!),
    loyaltyCurrencyNamePlural: Yup.string().required(t('modules.program_settings.validation_schema.1')!),
    loyaltyCurrencyNameMod24: Yup.string().required(t('modules.program_settings.validation_schema.1')!),
    loyaltyCurrencyNameMod50: Yup.string().required(t('modules.program_settings.validation_schema.1')!),
    bonusPriceCoverPercentage: Yup.number()
      .required(t('modules.program_settings.validation_schema.1')!)
      .min(1, t('modules.program_settings.validation_schema.2')!)
      .max(100, t('modules.program_settings.validation_schema.2')!),
    accrualPercentage: Yup.number()
      .required(t('modules.program_settings.validation_schema.1')!)
      .min(0, t('modules.program_settings.validation_schema.3')!)
      .max(100, t('modules.program_settings.validation_schema.3')!),
    applicableWithDiscount: Yup.boolean(),
  });

  // form submit
  async function submitHandler(
    values: LoyaltyDataValuesType,
    { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void }
  ) {
    setSubmitting(false);

    let dataRules = {};
    let dataProgram = {};

    if (isLegalityDocumentationOnServer) {
      // sending data without legalityDocumentationLink and legalityDocumentationId
      dataRules = {
        bonusActivePeriodDays: values.bonusActivePeriodDays,
        bonusPriceCoverPercentage: values.bonusPriceCoverPercentage,
        accrualPercentage: values.accrualPercentage,
        applicableWithDiscount: values.applicableWithDiscount,
      };

      dataProgram = {
        loyaltyCurrencyName: {
          mod1: values.loyaltyCurrencyNameMod1,
          plural: values.loyaltyCurrencyNamePlural,
          mod24: values.loyaltyCurrencyNameMod24,
          mod50: values.loyaltyCurrencyNameMod50,
        },
      };
    } else {
      dataRules = {
        bonusActivePeriodDays: values.bonusActivePeriodDays,
        bonusPriceCoverPercentage: values.bonusPriceCoverPercentage,
        accrualPercentage: values.accrualPercentage,
        applicableWithDiscount: values.applicableWithDiscount,
      };

      dataProgram = {
        legalityDocumentationId: legalityDocumentationFileId,
        legalityDocumentationLink: legalityDocumentationLink,
        loyaltyCurrencyName: {
          mod1: values.loyaltyCurrencyNameMod1,
          plural: values.loyaltyCurrencyNamePlural,
          mod24: values.loyaltyCurrencyNameMod24,
          mod50: values.loyaltyCurrencyNameMod50,
        },
      };
    }

    try {
      setLoading(true);
      await props.apolloClientProps.mutate({ mutation: CHANGE_LOYALTY_RULES, variables: { data: dataRules } });
      await props.apolloClientProps.mutate({ mutation: CHANGE_LOYALTY_PROGRAM, variables: { data: dataProgram } });

      message.success(t('modules.program_settings.message_success.0'), MESSAGE_TIME_DURATION);
      setIsFilledInput(false);
      setLegalityDocumentationLink(null);
      getLoyaltyData();
      resetForm();
    } catch (e) {
      console.error(e);
      setIsSaveError(true);
    } finally {
      setLoading(false);
    }
  }

  // get public link of uploaded file
  function getFilePublicLink(fileId: string) {
    fetchData(
      loading,
      setLoading,
      props.apolloClientProps.query({
        query: GET_FILE_PUBLIC_LINK,
        variables: { id: fileId },
        fetchPolicy: 'no-cache',
      }),
      setFilePublicLink,
      (res: any) =>
        deserialize(res, (o) =>
          o.required('data.file.getFileDescriptor').asObject((o: any) => ({
            publicLink: o.required('publicLink').asString,
          }))
        )
    );
  }

  // set public link of uploaded file to file name
  function previewFile() {
    window.open(`${filePublicLink?.publicLink}`, '_blank');
  }

  const uploadProps = {
    name: 'legalityDocumentation',
    accept: '.pdf',
    headers: {
      authorization: 'Bearer ' + props.authClientProps.credentials?.idToken,
    },
  };

  const CustomSelectComponent = ({ ...props }: any) => {
    const [field, meta, helpers] = useField(props.name);

    return (
      <Select
        {...field}
        value={loading ? t('modules.program_settings.select') : `${field.value}`}
        id={props.name}
        className="route-content_loyalty-select"
        size="large"
        onChange={(value: string) => {
          field.onChange(value);
          helpers.setTouched(true);
          if (value === 'null') {
            helpers.setValue(null, false);
          } else {
            helpers.setValue(+value, false);
          }
        }}
      >
        <Option key="0" value="null" className="route-content_loyalty-select-option">
          {t('modules.program_settings.option.0')}
        </Option>
        <Option key="1" value="365" className="route-content_loyalty-select-option">
          {t('modules.program_settings.option.1')}
        </Option>
        <Option key="2" value="183" className="route-content_loyalty-select-option">
          {t('modules.program_settings.option.2')}
        </Option>
        <Option key="3" value="90" className="route-content_loyalty-select-option">
          {t('modules.program_settings.option.3')}
        </Option>
        <Option key="4" value="30" className="route-content_loyalty-select-option">
          {t('modules.program_settings.option.4')}
        </Option>
      </Select>
    );
  };

  return (
    <Formik
      initialValues={{
        legalityDocumentation: undefined,
        legalityDocumentationLink: '',
        loyaltyCurrencyNameMod1: loyaltyData?.loyaltyCurrencyName.mod1,
        loyaltyCurrencyNamePlural: loyaltyData?.loyaltyCurrencyName.plural,
        loyaltyCurrencyNameMod24: loyaltyData?.loyaltyCurrencyName.mod24,
        loyaltyCurrencyNameMod50: loyaltyData?.loyaltyCurrencyName.mod50,
        bonusPriceCoverPercentage: loyaltyData?.bonusPriceCoverPercentage,
        bonusActivePeriodDays: loyaltyData?.bonusActivePeriodDays,
        accrualPercentage: loyaltyData?.accrualPercentage,
        applicableWithDiscount: loyaltyData?.applicableWithDiscount,
      }}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit} onBlur={formikProps.handleBlur}>
          <h1>{t('modules.program_settings.h1')}</h1>
          <div className="route-content route-content_loyalty" id="custom-prompt">
            <h2 className="route-content_loyalty-subtitle">
              {t('modules.program_settings.h2.0')}&nbsp;{t('modules.program_settings.h2.1')}
            </h2>
            <Icon
              type="info-circle"
              className="route-content_loyalty-info-circle route-content_loyalty-info-circle_legality-documentation"
            />
            <ModalHoverLegalityDocumentation />

            <div className="route-content_loyalty-legality-documentation-wrapper">
              <div
                className={
                  isLegalityDocumentationOnServer
                    ? 'route-content_loyalty-legality-documentation-link-block'
                    : 'route-content_loyalty_hidden'
                }
              >
                <Icon type="paper-clip" />
                {loyaltyData?.legalityDocumentation ? (
                  <a
                    href={loyaltyData?.legalityDocumentation?.publicLink}
                    className="route-content_loyalty-input-link-value"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {loyaltyData?.legalityDocumentation?.fileName}
                  </a>
                ) : (
                  <a
                    href={loyaltyData?.legalityDocumentationLink}
                    className="route-content_loyalty-input-link-value"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {loyaltyData?.legalityDocumentationLink}
                  </a>
                )}
                <Icon
                  type="close"
                  className="route-content_loyalty-link-icon"
                  onClick={() => {
                    setIsLegalityDocumentationOnServer(false);
                    formikProps.values.legalityDocumentationLink = '';
                  }}
                />
              </div>

              <div className={isLegalityDocumentationOnServer ? 'route-content_loyalty_hidden' : ''}>
                <div
                  className={
                    isUploaded
                      ? 'route-content_loyalty-legality-documentation-block route-content_loyalty-legality-documentation-block_without-padding'
                      : 'route-content_loyalty-legality-documentation-block'
                  }
                >
                  <FieldArray name="legalityDocumentation">
                    {(arrayHelper) => (
                      <>
                        <Upload
                          {...uploadProps}
                          id="legalityDocumentation"
                          action={window.parametrize('REACT_APP_FILE_STORAGE')}
                          showUploadList={true}
                          onPreview={previewFile}
                          onChange={(info: any) => {
                            if (info.file.status !== 'uploading') {
                              setLoading(true);
                            }
                            if (info.file.status === 'done') {
                              arrayHelper.replace(0, info.fileList);
                              message.success(t('modules.program_settings.message_success.1'), MESSAGE_TIME_DURATION);
                              const fileId = pathOr([''], ['file', 'response', 'fileIds'], info)[0];
                              getFilePublicLink(fileId);
                              setLegalityDocumentationFileId(fileId);
                              setIsUploaded(true);
                            }
                            if (info.file.status === 'removed') {
                              setIsUploaded(false);
                              setIsFilledInput(false);
                              setLoading(false);
                              setLegalityDocumentationFileId(null);
                              arrayHelper.remove(0);
                            }
                          }}
                        >
                          <Button
                            id="ProgramSettingsUploadFileButton"
                            className={
                              isFilledInput || isUploaded
                                ? 'route-content_loyalty_hidden'
                                : 'route-content_loyalty-upload-button'
                            }
                          >
                            <Icon
                              className={isFilledInput || isUploaded ? 'route-content_loyalty_hidden' : ''}
                              type="upload"
                            />
                            {t('modules.program_settings.button.0')}
                          </Button>
                        </Upload>
                      </>
                    )}
                  </FieldArray>

                  <span
                    className={
                      isFilledInput || isUploaded
                        ? 'route-content_loyalty_hidden'
                        : 'route-content_loyalty-text route-content_loyalty-text_margin-s'
                    }
                  >
                    {t('modules.program_settings.span.0')}
                  </span>

                  <label
                    htmlFor="legalityDocumentationLink"
                    className={
                      isUploaded
                        ? 'route-content_loyalty_hidden'
                        : 'route-content_loyalty-label route-content_loyalty-label_legality-documentation'
                    }
                  >
                    <div className="route-content_loyalty-input-link-wrapper">
                      <Field
                        type="text"
                        id="legalityDocumentationLink"
                        name="legalityDocumentationLink"
                        className={
                          isFilledInput || isUploaded
                            ? 'route-content_loyalty_hidden'
                            : 'route-content_loyalty-input-link'
                        }
                        placeholder={t('modules.program_settings.field.0')}
                        autoComplete="off"
                        spellCheck="false"
                      />
                      {loading ? (
                        <Icon
                          type="loading"
                          className="route-content_loyalty-loading-icon route-content_loyalty-loading-icon_documentation"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={isFilledInput ? '' : 'route-content_loyalty_hidden'}>
                      <Icon type="paper-clip" />
                      <a
                        href={formikProps.values.legalityDocumentationLink}
                        className="route-content_loyalty-input-link-value"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formikProps.values.legalityDocumentationLink}
                      </a>
                      <Icon
                        type="close"
                        className="route-content_loyalty-link-icon"
                        onClick={() => {
                          setIsFilledInput(false);
                          setLegalityDocumentationLink(null);
                          formikProps.values.legalityDocumentationLink = '';
                        }}
                      />
                    </div>
                  </label>
                  <ErrorMessage
                    className="error-message error-message_link"
                    name="legalityDocumentationLink"
                    component="div"
                  />
                </div>
              </div>
            </div>

            <h2 className="route-content_loyalty-subtitle">{t('modules.program_settings.h2.2')}</h2>
            <Icon
              type="info-circle"
              className="route-content_loyalty-info-circle route-content_loyalty-info-circle_name"
            />
            <ModalHoverName />

            <p className="route-content_loyalty-text">
              {t('modules.program_settings.p.0')}&nbsp;{t('modules.program_settings.p.1')}
            </p>

            <label
              htmlFor="loyaltyCurrencyNameMod1"
              className="route-content_loyalty-label route-content_loyalty-label_description-top"
            >
              <span className="route-content_loyalty-label-description">{t('modules.program_settings.span.1')}</span>
              <div className="route-content_loyalty-input-wrapper">
                <Field
                  id="loyaltyCurrencyNameMod1"
                  name="loyaltyCurrencyNameMod1"
                  className="route-content_loyalty-input"
                  placeholder={t('modules.program_settings.field.1')}
                  autoComplete="off"
                  spellCheck="false"
                />
                {loading ? <Icon type="loading" className="route-content_loyalty-loading-icon" /> : ''}
              </div>
              <ErrorMessage
                className="error-message error-message_description"
                name="loyaltyCurrencyNameMod1"
                component="div"
              />
            </label>

            <label
              htmlFor="loyaltyCurrencyNamePlural"
              className="route-content_loyalty-label route-content_loyalty-label_description-top"
            >
              <span className="route-content_loyalty-label-description">{t('modules.program_settings.span.2')}</span>
              <div className="route-content_loyalty-input-wrapper">
                <Field
                  id="loyaltyCurrencyNamePlural"
                  name="loyaltyCurrencyNamePlural"
                  className="route-content_loyalty-input"
                  placeholder={t('modules.program_settings.field.2')}
                  autoComplete="off"
                  spellCheck="false"
                />
                {loading ? <Icon type="loading" className="route-content_loyalty-loading-icon" /> : ''}
              </div>
              <ErrorMessage
                className="error-message error-message_description"
                name="loyaltyCurrencyNamePlural"
                component="div"
              />
            </label>

            <label
              htmlFor="loyaltyCurrencyNameMod24"
              className="route-content_loyalty-label route-content_loyalty-label_description-left"
            >
              <span className="route-content_loyalty-label-text">{t('modules.program_settings.span.3')}</span>
              <div className="route-content_loyalty-input-wrapper">
                <Field
                  id="loyaltyCurrencyNameMod24"
                  name="loyaltyCurrencyNameMod24"
                  className="route-content_loyalty-input"
                  placeholder={t('modules.program_settings.field.3')}
                  autoComplete="off"
                  spellCheck="false"
                />
                {loading ? <Icon type="loading" className="route-content_loyalty-loading-icon" /> : ''}
              </div>
              <ErrorMessage
                className="error-message error-message_text"
                name="loyaltyCurrencyNameMod24"
                component="div"
              />
            </label>

            <label
              htmlFor="loyaltyCurrencyNameMod50"
              className="route-content_loyalty-label route-content_loyalty-label_description-left"
            >
              <span className="route-content_loyalty-label-text">{t('modules.program_settings.span.4')}</span>
              <div className="route-content_loyalty-input-wrapper">
                <Field
                  id="loyaltyCurrencyNameMod50"
                  name="loyaltyCurrencyNameMod50"
                  className="route-content_loyalty-input route-content_loyalty-input_margins-mod"
                  placeholder={t('modules.program_settings.field.4')}
                  autoComplete="off"
                  spellCheck="false"
                />
                {loading ? <Icon type="loading" className="route-content_loyalty-loading-icon" /> : ''}
              </div>
              <ErrorMessage
                className="error-message error-message_text error-message_text_margin-min"
                name="loyaltyCurrencyNameMod50"
                component="div"
              />
            </label>
          </div>

          <div className="route-content route-content_loyalty">
            <h2 className="route-content_loyalty-subtitle">{t('modules.program_settings.h2.3')}</h2>

            <label
              htmlFor="accrualPercentage"
              className="route-content_loyalty-label route-content_loyalty-label_percentage"
            >
              <span className="route-content_loyalty-label-text">{t('modules.program_settings.span.5')}</span>
              <div className="route-content_loyalty-input-wrapper">
                <Field
                  type="number"
                  id="accrualPercentage"
                  name="accrualPercentage"
                  className="route-content_loyalty-input-percent"
                  min="0"
                  max="100"
                />
                {loading ? (
                  <Icon
                    type="loading"
                    className="route-content_loyalty-loading-icon route-content_loyalty-loading-icon_percent"
                  />
                ) : (
                  ''
                )}
              </div>
              <span className="route-content_loyalty-label-text">% {t('modules.program_settings.span.6')}</span>
              <ErrorMessage
                className="error-message error-message_accrual-percentage"
                name="accrualPercentage"
                component="div"
              />
            </label>

            <label htmlFor="bonusActivePeriodDays" className="route-content_loyalty-label">
              <span className="route-content_loyalty-label-text">{t('modules.program_settings.span.7')}:</span>
              <div className="route-content_loyalty-input-wrapper">
                <CustomSelectComponent name={'bonusActivePeriodDays'} />
                {loading ? (
                  <Icon
                    type="loading"
                    className="route-content_loyalty-loading-icon route-content_loyalty-loading-icon_select"
                  />
                ) : (
                  ''
                )}
              </div>
            </label>

            <label
              htmlFor="bonusPriceCoverPercentage"
              className="route-content_loyalty-label route-content_loyalty-label_percentage"
            >
              <span className="route-content_loyalty-label-text">{t('modules.program_settings.span.8')} </span>
              <div className="route-content_loyalty-input-wrapper">
                <Field
                  type="number"
                  id="bonusPriceCoverPercentage"
                  name="bonusPriceCoverPercentage"
                  className="route-content_loyalty-input-percent"
                  min="1"
                  max="100"
                />
                {loading ? (
                  <Icon
                    type="loading"
                    className="route-content_loyalty-loading-icon route-content_loyalty-loading-icon_percent"
                  />
                ) : (
                  ''
                )}
              </div>
              <span className="route-content_loyalty-label-text">% {t('modules.program_settings.span.9')}</span>
              <ErrorMessage
                className="error-message error-message_bonus-price"
                name="bonusPriceCoverPercentage"
                component="div"
              />
            </label>

            <label htmlFor="applicableWithDiscount" className="route-content_loyalty-checkbox-text">
              {loading ? (
                <Icon
                  type="loading"
                  className="route-content_loyalty-loading-icon route-content_loyalty-loading-icon_checkbox"
                />
              ) : (
                <Field
                  type="checkbox"
                  id="applicableWithDiscount"
                  name="applicableWithDiscount"
                  className="route-content_loyalty-checkbox"
                />
              )}
              {t('modules.program_settings.label')}
            </label>

            <div className="route-content_loyalty-buttons-block">
              <ModalDeactivationWarning activeProgramState={props.activeProgramState} />
              <button
                id="ProgramSettingsSaveChangesButton"
                type="submit"
                className={
                  !formikProps.isValid
                    ? 'route-content_loyalty-save-button'
                    : 'route-content_loyalty-save-button route-content_loyalty-save-button_active'
                }
                disabled={!formikProps.isValid}
              >
                {t('modules.program_settings.button.1')}
              </button>
            </div>
          </div>

          <RouteLeavingGuard
            when={formikProps.dirty}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => {
              return true;
            }}
          />

          <ModalSaveError isSaveError={isSaveError} setIsSaveError={setIsSaveError} />
        </form>
      )}
    </Formik>
  );
}

export default withApollo(ProgramSettings);
